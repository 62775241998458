import { useSearchBox } from 'react-instantsearch-hooks-web';

import styles from './SeachBox.module.scss';
import { Icon, ComponentCtaButton } from '@/ui';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

interface SearchBoxProps {
  resultsForText: string;
  searchText: string;
  slug: string;
  type?: 'search' | 'page';
}

export const SearchBox = ({
  resultsForText,
  searchText,
  slug,
  type = 'search',
}: SearchBoxProps) => {
  const [value, setValue] = useState('');
  const [refinement, setRefinement] = useState('');
  const displaySearch = refinement.length > 2;
  const { refine } = useSearchBox();

  const router = useRouter();

  useEffect(() => {
    if (window.location.search) {
      if (window.location.search?.split('=')[0] === '?query'){
        setValue(window.location.search?.split('=')[1].replace('%20', ' '));
      }
    }
  }, [router]);

  useEffect(() => {
    setRefinement(value.replaceAll('+', ' '));
    setTimeout(() => refine(value), 0);
  }, [value, refine]);

  return (
    <div className={styles.searchBox__item}>
      <label>{resultsForText}</label>
      <div className={styles.searchBox__item__input}>
        <div className={styles.searchBox__item__inputIcon}>
          <Icon id='search' width={24} height={24} />
        </div>
        <input
          type='text'
          autoFocus
          value={refinement}
          onChange={(event) => setRefinement(event.currentTarget.value)}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              if (displaySearch) {
                router.query = { query: refinement };
                if (type == 'search') {
                  router.pathname = slug;
                  router.push({
                    pathname: router.pathname,
                    query: router.query,
                  });
                } else {
                  router.replace(
                    { pathname: router.pathname, query: router.query },
                    undefined,
                    {
                      shallow: true,
                    }
                  );
                }
              }
            }
          }}
          placeholder='Try... ‘Passage Planning’'
        />

        {type === 'search' ? (
          <ComponentCtaButton
            ctaType='link'
            ctaStyle='solid'
            ctaIcon='arrow'
            ctaText={searchText}
            cssClass={`dark-blue ${displaySearch ? '' : 'searchDisabled'}`}
            ctaUrl={slug}
            ctaUrlParams={displaySearch ? { query: refinement } : undefined}
          />
        ) : (
          <ComponentCtaButton
            onClick={() => {
              if (displaySearch) {
                refine(refinement);
                router.query = { query: refinement };                
                router.replace(
                  { pathname: router.pathname, query: router.query },
                  undefined,
                  {
                    shallow: true,
                  }
                );                
              }
            }}
            ctaType='button'
            ctaStyle='outline'
            ctaIcon='arrow'
            ctaText={searchText}
            cssClass={`dark-blue ${displaySearch ? '' : 'searchDisabled'}`}
          />
        )}
      </div>
    </div>
  );
};
