import { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { UIContext } from '@/ui/utils';
import { IBreadcrumb } from '@/types';

interface SchemaProps {
  pageData: any;
}

export default function Schema({ pageData }: SchemaProps) {
  const { slug, insightTypeReference } = pageData;
  const fullPath = typeof window !== 'undefined' && window?.location?.href;
  const { asPath, locale } = useRouter();
  const paths = asPath.split('/');

  const pathsWithPageTitle: IBreadcrumb[] = paths
    .slice(1)
    .map((path, index) => ({
      pageTitle: path,
      path: paths.slice(0, index + 2).join('/'),
    }))
    .filter((path) => path.path !== '');

  const isHomePage = slug === 'home-page' || slug === `home-page-french`;

  const { siteName, logo, twitterLink, youTubeLink, linkedInLink } =
    useContext(UIContext);

  const [structuredData, setStructuredData] = useState<any[]>([]);

  useEffect(() => {
    const data: any[] = [];

    // Breadcrumb Schema
    const breadcrumbSchema = {
      '@context': 'https://schema.org/',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Home',
          item: `/${locale}/`,
        },
        ...pathsWithPageTitle
          .map((item, index) => ({
            '@type': 'ListItem',
            position: index + 2,
            name: item?.pageTitle || '',
            item: `/${locale}${item?.path || ''}`,
          }))
          .filter((item) => item?.name && item?.item),
      ],
    };

    data.push(breadcrumbSchema);

    const organizationSchema = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: siteName,
      url: fullPath,
      logo: {
        '@type': 'ImageObject',
        url: logo.url,
      },
      sameAs: [twitterLink, youTubeLink, linkedInLink],
    };
    data.push(organizationSchema);

    if (isHomePage) {
      const homePageSchema = {
        '@context': 'https://schema.org/',
        '@type': 'WebSite',
        name: siteName,
        url: fullPath,
        potentialAction: {
          '@type': 'SearchAction',
          target: '{search_term_string}',
          'query-input': 'required name=search_term_string',
        },
      };
      data.push(homePageSchema);
    }

    if (insightTypeReference != null) {
      const articleSchema = {
        '@context': 'https://schema.org',
        '@type': 'Article',
        headline: pageData.metaTitle ?? pageData.pageHeading,
        image: pageData.previewMedia?.url ?? logo.url,
        author: {
          '@type': 'Organization',
          name: pageData.writtenBy?.employeeName ?? siteName,
        },
        publisher: {
          '@type': 'Organization',
          name: siteName,
          logo: {
            '@type': 'ImageObject',
            url: logo.url,
          },
        },
        datePublished: pageData.publishDate,
      };
      data.push(articleSchema);
    }

    setStructuredData(data);
  }, [
    asPath,
    fullPath,
    locale,
    pageData,
    insightTypeReference,
    isHomePage,
    siteName,
    logo.url,
    twitterLink,
    youTubeLink,
    linkedInLink,
  ]);

  const schemaId = pageData?.sys?.id
    ? pageData.sys.id
    : asPath.replace('/', '');

  return (
    <>
      {structuredData.map((data) => (
        <Script
          id={`Schema-${data['@type']}`}
          type='application/ld+json'
          key={`Schema-${schemaId}-${data['@type']}`}
        >
          {JSON.stringify(data)}
        </Script>
      ))}
    </>
  );
}
