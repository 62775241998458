import styles from './ComponentMarketoBlock.module.scss';

import React, { useState } from 'react';
import { MarketoForm } from './MarketoForm';
import { useScrollLock } from '@/ui/hooks';
import { ComponentCtaButton, Icon, Modal } from '@/ui';
import { ComponentMarketoBlockTypes } from '@/types';

const ComponentMarketoBlock = ({
  sys,
  marketoHeading,
  marketoBody,
  marketoCtaText,
  marketoEmailText,
  marketoEmailExampleText,
  marketoTopics,
  marketoTopicText,
  marketoTopicPromptText,
  marketoFormId,
  marketoSuccessMessage = 'Form submitted',
}: ComponentMarketoBlockTypes) => {
  const id = `marketo-${sys?.id}`;
  const [isOpen, setIsOpen] = useState(false);
  const [blockScroll, allowScroll] = useScrollLock();
  // Keys match Marketo fields.
  const [prePopulatedFields, setPrePopulatedFields] = useState({
    Enquiry_topic__c: '',
    Email: '',
  });

  const handleFormOpen = () => {
    setIsOpen(true);
    blockScroll();
  };

  const handleFormClose = () => {
    setIsOpen(false);
    allowScroll();
  };

  return (
    <>
      <section className={`${styles.marketoBlock} ${styles.enquiry}`}>
        <div className={styles.marketoBlock__inner}>
          <div>
            <h2 className={styles.marketoBlock__heading}>{marketoHeading}</h2>
            <div className={styles.marketoBlock__body}>{marketoBody}</div>
          </div>
          <div className={styles.marketoBlock__formWrapper}>
            <div className={styles.marketoBlock__form}>
              <div className={styles.marketoBlock__formSection}>
                <label
                  htmlFor={`${sys?.id}-topics`}
                  className={styles.marketoBlock__label}
                >
                  {marketoTopicText}
                </label>
                <div className={styles.marketoBlock__selectContainer}>
                  <select
                    id={`${sys?.id}-topics`}
                    className={styles.marketoBlock__select}
                    defaultValue='default'
                    onChange={(event) =>
                      setPrePopulatedFields({
                        ...prePopulatedFields,
                        Enquiry_topic__c: event.target.value,
                      })
                    }
                  >
                    <option disabled value='default'>
                      {marketoTopicPromptText}
                    </option>
                    {marketoTopics?.map((topic) => (
                      <option key={topic} value={topic}>
                        {topic}
                      </option>
                    ))}
                  </select>
                  <Icon
                    id='chevron_down'
                    width={9}
                    height={6}
                    className={styles.marketoBlock__selectArrow}
                  />
                </div>
                <label
                  htmlFor={`${sys?.id}-email`}
                  className={styles.marketoBlock__label}
                >
                  {marketoEmailText}
                </label>
                <input
                  id={`${sys?.id}-email`}
                  className={styles.marketoBlock__input}
                  type='text'
                  placeholder={marketoEmailExampleText}
                  aria-describedby={`${sys?.id}-email`}
                  onChange={(event) =>
                    setPrePopulatedFields({
                      ...prePopulatedFields,
                      Email: event.target.value,
                    })
                  }
                />
              </div>
              <ComponentCtaButton
                ctaType='button'
                buttonType='button'
                ctaText={marketoCtaText}
                ctaStyle='solid'
                ctaIcon='arrow'
                ctaIconPosition='right'
                onClick={handleFormOpen}
                tracking={{ enquiry_open: marketoFormId }}
                id={id}
              />
            </div>
          </div>
        </div>
      </section>
      <Modal
        handleClose={() => handleFormClose()}
        isOpen={isOpen}
        triggerId={id}
      >
        <MarketoForm
          key='marketoEnquiry'
          formId={marketoFormId}
          prePopulatedFields={prePopulatedFields}
          successMessage={marketoSuccessMessage}
        />
      </Modal>
    </>
  );
};

export default ComponentMarketoBlock;
