import styles from './MobileNavigation.module.scss';

import { useContext } from 'react';
import { Config } from '@/ui/utils';
import { ComponentCtaButton, SearchClient, SearchHero, UIContext } from '@/ui';
import { NavigationProps } from '@/types';

import Navigation from './Navigation';
import CustomerLogin from '../CustomerLogin/CustomerLogin';
import { LanguageSelector } from '../LanguageSelector';

const MobileNavigation = ({
  headerRef,
  openDropdown,
  openSubDropdown,
  setOpenDropdown,
  setOpenSubDropdown,
  showSearch = true,
  menuDisplay = false,
}: NavigationProps & {
  headerRef: React.RefObject<HTMLDivElement>;
}) => {
  const regionSelector = Config.regionSelector;

  const mobileShow = menuDisplay ? styles['open'] : styles['closed'];

  const {
    backText,
    headerNavigation,
    contactUsText,
    contactUsLink,
    searchPage,
    searchTextShort,
  } = useContext(UIContext);

  const handleMobileSearch = () => {
    if (openDropdown === 'search') {
      setOpenDropdown(null);
    } else {
      setOpenDropdown('search');
    }
    setOpenSubDropdown(null);
  };

  const mobileMenuScrollTop = () => {
    const mainMenuScrollTo = document.querySelector('.main_menu_scrollto');
    mainMenuScrollTo?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <div className={`${styles.mobileMenu} ${mobileShow}`}>
      {openDropdown === 'search' && (
        <div
          className={styles.header__search__subNavWrapper}
          style={{ position: 'absolute', height: '100dvh', zIndex: '20' }}
        >
          <SearchClient>
            <SearchHero
              {...searchPage}
              searchText={searchTextShort}
              type='search'
              onCloseButtonClick={handleMobileSearch}
            />
          </SearchClient>
        </div>
      )}
      {showSearch && (
        <div className={`${styles.mobileMenu__top} ${openDropdown ? styles['dropdown_open'] : ''}`}>
          {!openDropdown && !openSubDropdown && (
            <ComponentCtaButton
              ctaType='button'
              ctaText={'Search One Ocean'}
              ctaStyle='outline'
              ctaIcon='search'
              ctaIconPosition='left'
              cssClass='mobile-search'
              onClick={handleMobileSearch}
            />
          )}
          {openDropdown && !openSubDropdown && openDropdown !== 'search' && (
            <ComponentCtaButton
              ctaType='button'
              ctaText={backText}
              ctaStyle='outline'
              ctaIconPosition='left'
              ctaIcon='arrow'
              cssClass='mobile-back'
              onClick={() => {
                setOpenDropdown(null);
                mobileMenuScrollTop();
              }}
            />
          )}
          {openSubDropdown && (
            <ComponentCtaButton
              ctaType='button'
              ctaText={backText}
              ctaStyle='outline'
              ctaIconPosition='left'
              ctaIcon='arrow'
              cssClass='mobile-back'
              onClick={() => {
                setOpenSubDropdown(null);
                mobileMenuScrollTop();
              }}
            />
          )}
        </div>
      )}
      <div className={styles.mobileMenu__main}>
        <div
          className={`${styles.mobileMenu__mainInner} ${
            openDropdown ? styles['dropdown-open'] : ''
          } ${openSubDropdown ? styles['subDropdown-open'] : ''}`}
        >
          <span className='main_menu_scrollto'></span>
          <Navigation
            headerRef={headerRef}
            menuItems={headerNavigation}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
            openSubDropdown={openSubDropdown}
            setOpenSubDropdown={setOpenSubDropdown}
            
          />
          <div className={styles.mobileMenu__contact}>
            <ComponentCtaButton
              ctaType='link'
              ctaText={contactUsText}
              ctaStyle='solid'
              ctaIcon='arrow'
              ctaIconPosition='right'
              ctaUrl={contactUsLink}
              cssClass='mobile_button'
            />
          </div>
          <div className={styles.mobileMenu__login}>
            <CustomerLogin />
          </div>
          <div className={styles.mobileMenu__language}>
            {regionSelector && <LanguageSelector />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNavigation;
