import { ArticleAnchorLink } from '@/types';

const DataArticleAnchor = ({ anchorPointId, anchorLinkTitle}: ArticleAnchorLink) => {
  return (
    <a id={anchorPointId} title={anchorLinkTitle} style={{
      display: "block", position: 'relative' }}></a>
  );
};

export default DataArticleAnchor;
