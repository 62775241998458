import React from 'react';
import styles from './FilterRadioButton.module.scss';
interface RadioButtonProps {
  id: string;
  text: string;
  name: string;
  onChangeFilters: Function;
  active: boolean;
}

export function FilterRadioButton({
  id,
  text,
  name,
  onChangeFilters,
  active,
}: RadioButtonProps) {
  const handleRadioButtonChange = () => {
    if (!active) {
      if (id === 'allresults') {
        onChangeFilters('All', null);
      } else {
        onChangeFilters(text, null);
      }
    }
  };

  return (
    <div className={styles.radioButtonContainer}>
      <label
        htmlFor={id}
        className={`${styles.radioButtonLabel} ${
          active ? styles['active'] : ''
        }`}
      >
        {text}
      </label>
      <input
        tabIndex={0}
        type='radio'
        checked={active}
        onChange={handleRadioButtonChange}
        id={id}
        name={name}
        aria-label={`radio button for ${text}`}
        className={styles.radioButtonInput}
      />
    </div>
  );
}

export default FilterRadioButton;
