import React, { useState, useEffect, useRef } from 'react';

import type { ComponentCta, PageAnchorTag } from '@/types';
import { ComponentCtaButton } from '../ComponentCtaButton';

import styles from './ComponentStickyMenu.module.scss';

interface StickyMenuProps {
  anchorLinks: Array<PageAnchorTag>;
  ctas: Array<ComponentCta>;
  styleColour: string;
}

const ComponentStickyMenu = ({
  anchorLinks,
  ctas,
  styleColour,
}: StickyMenuProps) => {
  const [activeLink, setActiveLink] = useState<string>('');
  const navigationRef = useRef<HTMLUListElement>(null);

  const smoothScrollToAnchor = (anchorId: string) => {
    const anchorElement = document.getElementById(anchorId);
    if (anchorElement) {
      const offsetTop =
        anchorElement.getBoundingClientRect().top + window.scrollY - 100;
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const smoothNavScroll = (activeItem: Element | null) => {
    if (activeItem) {
      const activeItemRect = activeItem.getBoundingClientRect();
      if (activeItemRect.left < 0) {
        activeItem.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'end',
        });
      } else if (activeItemRect.right > window.innerWidth) {
        activeItem.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'start',
        });
      }
    }
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    const stickyMenuContainer = document.querySelector('.js-sticky-menu') as HTMLElement;
    const stickyMenuPoint = document.querySelector('.sticky-menu-point') as HTMLElement;
    const headerHeight = document.querySelector('header') as HTMLElement;
    const headerOffset = headerHeight.offsetHeight - 1;

    if (stickyMenuContainer !== null) {
      if (scrollPosition >= stickyMenuPoint.offsetTop) {
        stickyMenuContainer.style.top = `${headerOffset}px`;
        stickyMenuContainer.style.position = 'fixed';
        stickyMenuPoint.style.marginBottom = `${stickyMenuContainer.offsetHeight}px`;
        stickyMenuContainer.classList.add('sticky-menu--active');
      } else {
        stickyMenuContainer.style.top = '';
        stickyMenuContainer.style.position = 'relative';
        stickyMenuPoint.style.marginBottom = '';
        stickyMenuContainer.classList.remove('sticky-menu--active');
      }
    }

    anchorLinks.forEach((anchor) => {
      const anchorElement = document.getElementById(anchor.id);
      if (anchorElement) {
        const offsetTop = anchorElement.offsetTop - 150;
        if (scrollPosition >= offsetTop) {
          setActiveLink(anchor.id);
        }
      }
    });

    if (navigationRef.current !== null && activeLink !== '') {
      const activeItem = navigationRef.current.querySelector(
        `a[href="#${activeLink}"]`
      );
      smoothNavScroll(activeItem);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeLink]);

  return (
    <>
      <div className={`sticky-menu-point`}></div>
      <div className={`${styles.stickyMenu} js-sticky-menu`}>
        <div className={styles.stickyMenu__container}>
          <div className={styles.stickyMenu__inner}>
            <div className={styles.stickyMenu__left}>
              <ul ref={navigationRef} className={styles.stickyMenu__navigation}>
                {anchorLinks.map((anchor: PageAnchorTag) => (
                  <li
                    key={`stickyMenuAnchor_${anchor.id}_key`}
                    className={`${styles.stickyMenu__item} ${
                      anchor.id === activeLink ? 'active' : ''
                    }`}
                  >
                    <a
                      tabIndex={0}
                      href={`#${anchor.id}`}
                      className={`${anchor.id === activeLink ? 'active' : ''}`}
                      onClick={(e) => {
                        e.preventDefault();
                        smoothScrollToAnchor(anchor.id);
                      }}
                    >
                      {anchor.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {ctas.length > 0 && (
              <div className={styles.stickyMenu__right}>
                <div className={styles.stickyMenu__cta}>
                  <ComponentCtaButton
                    key={ctas[0].ctaText}
                    ctaType='link'
                    ctaText={ctas[0].ctaText}
                    ctaAria={ctas[0].ctaAria}
                    ctaStyle={ctas[0].ctaStyle}
                    ctaIcon={ctas[0].ctaIcon}
                    ctaIconPosition={ctas[0].ctaIconPosition}
                    ctaUrl={ctas[0].ctaUrl}
                    ctaPage={ctas[0].ctaPage}
                    cssClass={styleColour}
                    ctaMarketoFormId={ctas[0].ctaMarketoFormId}
                    ctaDigitalAsset={ctas[0].ctaDigitalAsset}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ComponentStickyMenu;
