import styles from './Navigation.module.scss';

import { useIsMobile } from '@/ui/utils';
import type { NavigationProps, NavigationItem } from '@/types';

import HeaderNavItem from './HeaderNavItem/HeaderNavItem';

const Navigation = ({
  headerRef,
  menuItems,
  openDropdown,
  setOpenDropdown,
  openSubDropdown,
  setOpenSubDropdown,
}: NavigationProps & {
  headerRef: React.RefObject<HTMLDivElement>;
}) => {
  const isMobile = useIsMobile();

  return (
    <nav className={`${styles.nav} ${isMobile ? styles['mobile'] : ''}`}>
      <ul className={styles.nav__list}>
        {menuItems?.map((navigationItem: NavigationItem) => (
          <HeaderNavItem
            headerRef={headerRef}
            key={`headerNavItem__${navigationItem.sys.id}`}
            isOpen={openDropdown === navigationItem.sys.id}
            isToggle={() => {
              setOpenDropdown(
                openDropdown === navigationItem.sys.id
                  ? null
                  : navigationItem.sys.id
              );
            }}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
            openSubDropdown={openSubDropdown}
            setOpenSubDropdown={setOpenSubDropdown}
            {...navigationItem}
          />
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
