import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { format, parseISO } from 'date-fns';
import { enGB, fr } from 'date-fns/locale';
// @ts-ignore
import isDarkColor from 'is-dark-color';

import { ArticleHero, ComponentCta, DataTag } from '@/types';

import { colorPickerHexToClassName } from '@/ui/constants';
import { convertMarkdown, useIsMobile, UIContext } from '@/ui/utils';
import {
  ComponentCtaButton,
  HeroImageClip,
  HeroVideoClip,
  Breadcrumb,
  TagPill,
} from '@/ui/';

import styles from './ComponentArticleHero.module.scss';

const ComponentArticleHero = ({
  pageData,
  heroHeading,
  heroColour = 'white',
  heroSubheading,
  heroMedia,
  heroBody,
  ctas,
  showBreadcrumb = false,
}: ArticleHero) => {
  const { locale = 'en' } = useRouter();
  const currentLocale = locale;

  const { insightTypes, publishDate, tags } = pageData;

  const localeFormattedDate = publishDate
    ? format(parseISO(publishDate), 'dd MMMM yyyy', {
        locale: currentLocale === 'en' ? enGB : fr,
      })
    : null;

  let insightName = insightTypes?.insightName;

  heroColour = heroColour?.toLowerCase().replace(' ', '-');

  const styleColour = colorPickerHexToClassName[heroColour]
    ? colorPickerHexToClassName[heroColour]
    : heroColour;

  const { playVideoText, pauseVideoText } = useContext(UIContext);

  const [isPlaying, setIsPlaying] = useState(true);
  const [isVideo, setIsVideo] = useState(false);

  const isMobile = useIsMobile();
  const hasMedia = heroMedia?.url;

  const isImage = heroMedia?.contentType.startsWith('image');
  const hasCtas = !!ctas?.length;

  let captionText;
  if (locale !== 'en') {
    captionText = isImage ? 'Image:' : 'Vidéo:';
  } else {
    captionText = isImage ? 'Image:' : 'Video:';
  }

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const video = !isImage;
    if (video) {
      setIsVideo(video);
    }
  }, [heroMedia, isImage]);

  return (
    <div
      className={`${styles.articleHero} ${styles[styleColour]} ${
        !hasMedia ? styles['no-media'] : ''
      }`}
    >
      <div className={styles.articleHero__inner}>
        <div
          className={`${styles.articleHero__content} ${
            showBreadcrumb ? styles['breadcrumb'] : ''
          }`}
        >
          {showBreadcrumb && (
            <div className={styles.articleHero__breadcrumb}>
              <Breadcrumb usedOnLightBackground={!isDarkColor(heroColour)} />
            </div>
          )}

          <div className={styles.articleHero__articleInfo}>
            <div className={styles.articleHero__articleType}>{insightName}</div>
            {tags.length > 0 && (
              <div className={styles.articleHero__articleTags}>
                {tags.map((tag: DataTag) => {
                  return (
                    <div key={tag.tagName}>
                      <TagPill text={tag.tagName} isAccent={!!styleColour} />
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <h1 className={styles.articleHero__title}>{heroHeading}</h1>

          {heroBody && (
            <div className={styles.articleHero__description}>
              {convertMarkdown(heroBody)}
            </div>
          )}

          <div className={styles.articleHero__additionalInfo}>
            {publishDate && (
              <span className={styles.articleHero__date}>
                {localeFormattedDate}
              </span>
            )}

            {heroSubheading && (
              <span
                className={`${styles.articleHero__timetoread} ${
                  publishDate && heroSubheading ? styles.both : ''
                }`}
              >
                {heroSubheading}
              </span>
            )}
          </div>

          {hasCtas && (
            <div className={styles.articleHero__clickthroughs}>
              {ctas.map((cta: ComponentCta) => {
                return (
                  <ComponentCtaButton
                    key={cta.ctaText}
                    ctaType='link'
                    ctaText={cta.ctaText}
                    ctaAria={cta.ctaAria}
                    ctaStyle={cta.ctaStyle}
                    ctaIcon={cta.ctaIcon}
                    ctaIconPosition={cta.ctaIconPosition}
                    ctaUrl={cta.ctaUrl}
                    ctaPage={cta.ctaPage}
                    cssClass={styleColour}
                    ctaMarketoFormId={cta.ctaMarketoFormId}
                    ctaDigitalAsset={cta.ctaDigitalAsset}
                  />
                );
              })}
            </div>
          )}

          {isImage && !isMobile && heroMedia?.description && (
            <div className={styles.articleHero__caption}>
              <span>
                {captionText} {heroMedia?.description}
              </span>
            </div>
          )}
        </div>
        <div className={styles.articleHero__image}>
          {isVideo && hasMedia && (
            <button
              type='button'
              className={`${styles.articleHero__videoButton} ${
                isPlaying ? styles.pause : styles.play
              }`}
              onClick={togglePlayPause}
            >
              {!isMobile && (isPlaying ? pauseVideoText : playVideoText)}
            </button>
          )}
          <div className={styles.articleHero__imageWrapper}>
            {isImage && <HeroImageClip heroMedia={heroMedia} priority />}
            {isVideo && (
              <HeroVideoClip
                heroMedia={heroMedia}
                isPlaying={isPlaying}
                priority
              />
            )}
          </div>
        </div>
        {isMobile && heroMedia?.description && (
          <div className={styles.articleHero__captionWrapper}>
            <div className={styles.articleHero__caption}>
              <span>
                {captionText} {heroMedia.description}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ComponentArticleHero;
