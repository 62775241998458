import styles from './LanguageSelector.module.scss';

import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import type { IPath } from "@/types";

import { localeText } from '@/ui/constants';
import { UIContext } from '@/ui';

const LanguageSelector = ({
  topBarRef,
}: {
  topBarRef?: React.RefObject<HTMLDivElement>;
}) => {
  const router = useRouter();
  const { locale = 'en', locales, query, pathname, asPath } = router;
  const { paths } = useContext(UIContext);

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleLanguage = () => {
    setShowDropdown(!showDropdown);
  };

  const handleRelativeLocalePath = (locale: string) => {
    let relativeLocalePath = locale;

    if (pathname !== asPath) {
      const currentPath = paths.find((item: IPath) => JSON.stringify(item.params.slug) === JSON.stringify(query.slug));

      if (currentPath) {
        const { id: entryId } = currentPath.params;
        const relativePath = paths.find((path: IPath) => path.locale === locale && path.params?.id === entryId);
  
        relativeLocalePath = relativePath ? relativePath?.params?.slug.join('/') : locale;
      }
    } else {
      relativeLocalePath = pathname;
    }

    router.push(relativeLocalePath, relativeLocalePath, { locale });
    setShowDropdown(false);
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        event.target instanceof Node &&
        !dropdownRef.current.contains(event.target) &&
        topBarRef?.current &&
        !topBarRef?.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    },
    [setShowDropdown, dropdownRef, topBarRef]
  );

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  const languageText = localeText[locale];

  return (
    <div
      className={`${styles.languageSelector} ${
        showDropdown ? styles['open'] : ''
      }`}
    >
      <button
        type='button'
        className={`${styles.languageSelector__setItem} ${styles[locale]}`}
        aria-label='Language Selector'
        onClick={() => handleLanguage()}
      >
        <div className={styles.languageSelector__text}>{languageText}</div>
        <div className={styles.languageSelector__arrow}></div>
      </button>

      {showDropdown && (
        <div ref={dropdownRef} className={styles.languageSelector__dropdown}>
          {locales?.map((l) => {
            return (
              <div
                key={l}
                className={`${styles.languageSelector__language} ${styles[l]}`}
              >
                <button
                  type="button"
                  onClick={() => handleRelativeLocalePath(l)}
                  className={`${styles.languageSelector__item}`}
                >
                  {localeText[l]}
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default LanguageSelector;
