import { Config } from '@/ui/utils';

import type { BaseQueryProps } from '@/types';

import { insightsPageComponentCollectionFields } from '@/intergrations';
import { allStandardPages } from './base';


export interface GetInsightsQueryProps extends BaseQueryProps {
  slug?: string;
  filters?: {
    locale: string;
    page: string;
    insightTypes: string[];
    pageTags: string[];
  };
  insightTypes: string[];
}

export const insightsQuery = ({
  slug,
  locale,
  preview = false,
  limit = 0,
  filters = {
    locale: 'en',
    page: '1',
    pageTags: [],
    insightTypes: [],
  },
  insightTypes = []
}: GetInsightsQueryProps) => {

  const slugFilter = slug ? `, slug: "${slug}"` : ''; // This needs refined - not sure how to combined WHERE conditions

  let previewTrue = preview ? 'preview: true' : '';

  return `query {
    pageInsightsListingCollection(
      limit: 1
      locale: "${locale}"
      ${previewTrue}
      ) {
      items {
        __typename
        sys {
          id
        }  
        pageTitle
        pageHeading
        filterText
        topicsText
        filterByText
        filteredByText
        applyFiltersText
        clearAllFiltersText
        filterInsights 
        contentTypeText
        noFiltersAvailableText
        allResultsText
        metaTitle
        metaDescription
        ${insightsPageComponentCollectionFields}
      }
    }
    ${allStandardPages({ locale, limit, preview, slugFilter, filters, insightTypes })}
  }
`;
};
