import styles from './ComponentTestimonialCarousel.module.scss';

import Image from 'next/image';

import { Carousel, CarouselSlide, ResponsiveImage } from '@/ui/base';

import { TestimonialCarouselComponent, TestimonialCarouselItem } from '@/types';

const ComponentTestimonialCarousel = ({
  sys,
  carouselHeading,
  slideXLabel,
  slideXCountLabel,
  nextLabel,
  previousLabel,
  testimonials,
}: TestimonialCarouselComponent) => {
  return (
    <div className={styles.testimonialCarousel}>
      <Carousel
        id={sys?.id ?? 'testimonial-carousel'}
        className={styles.testimonialCarousel__inner}
        loadedClass={styles.loaded}
        accessibleTitle={carouselHeading}
        slideCountLabel={slideXCountLabel}
        slideLabel={slideXLabel}
        previousLabel={previousLabel}
        nextLabel={nextLabel}
      >
        {testimonials.map((testimonial: TestimonialCarouselItem, index: number) => {
          const {
            sys,
            testimonialName,
            testimonialQuote,
            testimonialNameSub,
            testimonialImage,
          } = testimonial;

          const hasImage = !!testimonialImage?.url;
          const hasName = !!testimonialName;
          const hasNameSub = !!testimonialNameSub;

          return (
            <CarouselSlide
              key={`${sys?.id}-${index}`}
              className={styles.testimonialCarousel__slideWrapper}
            >
              <figure className={styles.testimonialCarousel__slide}>
                <blockquote className={styles.testimonialCarousel__quote}>
                  {testimonialQuote}
                </blockquote>
                {(hasImage || hasName || hasNameSub) && (
                  <figcaption className={styles.testimonialCarousel__caption}>
                    {hasImage && (
                      <div className={styles.testimonialCarousel__imageWrapper}>
                        <ResponsiveImage
                          media={testimonialImage}
                          sizes={{ sm: 48 }}
                          className={styles.testimonialCarousel__image}
                        />
                      </div>
                    )}
                    {(hasName || hasNameSub) && (
                      <div className={styles.testimonialCarousel__nameWrapper}>
                        {hasName && (
                          <div className={styles.testimonialCarousel__name}>
                            {testimonialName}
                          </div>
                        )}
                        {hasNameSub && (
                          <div className={styles.testimonialCarousel__subname}>
                            {testimonialNameSub}
                          </div>
                        )}
                      </div>
                    )}
                  </figcaption>
                )}
              </figure>
            </CarouselSlide>
          );
        })}
      </Carousel>
    </div>
  );
};

export default ComponentTestimonialCarousel;
