import {
  sysId,
  dataNavItemFields,
  ctaButtonFields,
  navigationItemFieldsOne,
  dataEmployeeFields,
  insightTypeReferenceFields,
  pageTagCollectionFields,
} from './components';

export const siteConfigurationFields = `
    __typename
    sys { 
      id
    }
    siteName
    logo {
      title
      url
    }
    gtmId
    gtmEnvironment
    gtmAuth
    fallbackEmployeeProfileImage {
      url
      width
      height
      title
      description
    }
    contactUsText
    contactUsLink
    customerLoginText
    customerLoginLink
    searchPage {
      pageHeading
      slug
      resultsForText
      popularPagesText
      popularPagesCollection(limit: 6) {
        items {
          __typename
          sys { 
            id
          }
          pageHeading
          slug
        }
      }
    }
    searchTextShort
    searchTextLong
    backText
    closeText
    menuText
    overviewText
    solutionsForText
    exploreText
    relatedTopicsText
    dataDrivenInsightText
    playVideoText
    pauseVideoText
    headerNavigationCollection(limit: 6) {
      total
      items {
        ${sysId}
        navText
        navUrl
        navDescription
        navChildrenCollection(limit: 4) {
            items {
                ... on DataNavItem {${navigationItemFieldsOne}}
            }
        }
        navOverarchCta {
            pageHeading
            slug
        }
        navOverarchCtaTextOverride
      }
    }
    footerLogo {
      title
      url
    }
    footerNavigationCollection(limit: 2) {
      total
      items {
        ${sysId}
        navUrl
        navText
        navChildrenCollection(limit: 8) {
            items {
                ... on DataNavItem {${dataNavItemFields}}
            }
        }
      }
    }
    footerLegalText,
    copyrightText,
    footerCalloutText,
    footerLegalNavigationCollection(limit: 8) {
        items {
            ... on DataNavItem {${dataNavItemFields}}
        }
    }
    footerCtaCollection(limit: 1) {
        items {
            ... on ComponentCta {${ctaButtonFields}}
        }
    }
    twitterHandle
    twitterLink
    youTubeLink
    linkedInLink
    contentSecurityPolicy
    fallbackMetaDataImage {
      url
      width
      height
      title
      contentType
      description
    }
    marketoMunchkinId
    cookieProId
    globalNewsletterComponentId
`;

export const pageBaseFields = `
  __typename
  sys { 
    id
  }  
  pageTitle
  pageHeading
  slug
  metaTitle
  metaDescription
  canonicalUrl
  noIndex
  noFollow
  includeInSiteMap  
  previewMedia(locale: "en") {
    url
    description
    width
    height
    contentType
    description
  }
  previewText  
`;

export const standardPageFields = `
  ${pageBaseFields}
  publishDate
  showHeader
  showNewsletterSignup
  showFooter
  scriptField
  writtenBy {
    ${dataEmployeeFields}
  }
  ${insightTypeReferenceFields}
  ${pageTagCollectionFields}
`