import Head from 'next/head';
import { useRouter } from 'next/router';
import { useContext } from 'react';

import { UIContext } from '@/ui';

export const PageHead = ({ pageData }: any) => {
  const { locale = 'en' } = useRouter();
  const fullPath = typeof window !== 'undefined' && window?.location?.href;
  const {
    metaTitle = '',
    metaDescription = '',
    previewMedia = null,
    writtenBy = {},
    canonicalUrl = '',
    noIndex,
    noFollow,
    insightTypeReference,
  } = pageData;

  const {
    twitterHandle,
    contentSecurityPolicy = '',
    fallbackMetaDataImage,
  } = useContext(UIContext);

  let pageType, shouldSetNoIndex;

  if (canonicalUrl) {
    shouldSetNoIndex = false;
  } else {
    shouldSetNoIndex = noIndex;
  }

  if (insightTypeReference != null) {
    pageType = 'article';
  } else {
    pageType = 'website';
  }

  const metaImage = previewMedia ?? fallbackMetaDataImage;

  const csp = contentSecurityPolicy
    ? contentSecurityPolicy.replace(/(\r\n|\n|\r)/gm, '')
    : '';

  return (
    <Head>
      <title>{metaTitle}</title>
      <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />

      {!!canonicalUrl ? (
        <link rel='canonical' href={canonicalUrl} />
      ) : (
        <link rel='canonical' href={fullPath || ''} />
      )}

      {!!metaDescription && (
        <meta name='description' content={metaDescription} />
      )}
      {!!writtenBy?.employeeName && (
        <meta name='author' content={writtenBy?.employeeName} />
      )}

      {/* Noindex | Nofollow */}
      {(shouldSetNoIndex || noFollow) && (
        <meta
          name='robots'
          content={`${shouldSetNoIndex ? 'noindex' : ''}${
            shouldSetNoIndex && noFollow ? ',' : ''
          }${noFollow ? 'nofollow' : ''}`}
        />
      )}

      {/* OG / Facebook */}
      <meta property='og:type' content={pageType} />
      <meta property='og:locale' content={locale} />
      <meta property='og:url' content={`${canonicalUrl ?? fullPath}`} />
      <meta property='og:site_name' content='OneOcean' />
      <meta property='og:title' content={metaTitle} />
      <meta property='og:description' content={metaDescription} />
      <meta property='og:image' content={metaImage.url} />
      <meta property='og:image:width' content={metaImage.width} />
      <meta property='og:image:height' content={metaImage.height} />
      <meta property='og:image:type' content={metaImage.contentType} />
      <meta property='og:image:alt' content={metaImage.description} />

      {/* Twitter */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta property='twitter:domain' content='oneocean.com' />
      <meta property='twitter:url' content={`${canonicalUrl ?? fullPath}`} />
      <meta name='twitter:title' content={metaTitle} />
      <meta name='twitter:description' content={metaDescription} />
      <meta name='twitter:site' content={twitterHandle} />
      <meta name='twitter:image' content={metaImage.url} />
      <meta name='twitter:image:alt' content={metaImage.description} />
      {!!writtenBy && <meta name='twitter:creator' content={twitterHandle} />}

      {/* Favicon */}
      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/apple-touch-icon.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href='/favicon-32x32.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href='/favicon-16x16.png'
      />

      <link rel='manifest' href='/site.webmanifest' />
      <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />

      <meta name='msapplication-TileColor' content='#da532c' />
      <meta name='theme-color' content='#ffffff'></meta>

      {/* Google site verification */}
      <meta
        name='google-site-verification'
        content='21tcQEhSr6EpdUiK1KiVb1sPzeP7v3ijHoJJXfvO4X0'
      />
      {/* Content Security Policies */}
      {csp && <meta httpEquiv='Content-Security-Policy' content={csp} />}
    </Head>
  );
};
