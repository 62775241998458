export const CONTENTFUL_ENVIRONMENT = process.env.CONTENTFUL_ENVIRONMENT;

export const CONTENTFUL_SPACE_ID = process.env.CONTENTFUL_SPACE_ID;

export const CONTENTFUL_ACCESS_TOKEN = process.env.CONTENTFUL_ACCESS_TOKEN;

export const CONTENTFUL_PREVIEW_ACCESS_TOKEN = process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN;

export const CONTENTFUL_MANAGEMENT_ACCESS_TOKEN = process.env.CONTENTFUL_MANAGEMENT_ACCESS_TOKEN;

// Maximum GraphQL query size that can be sent normally is 8192 bytes, 
// so by setting the value to 8191 we can ensure that the query will be sent as 
// a Automatic Persisted Query if it is over this size
export const MAX_QUERY_SIZE = 8191;

// The calculated size is smaller than contentful's calculated size, 
// so we need to add a discrepancy
export const SIZE_DESCREPANCY = 50;

export const APQ_LOG = process.env.APQ_LOG;