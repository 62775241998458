export const breakpoints: { [key: string]: number } = {
  xs: 381,
  sm: 481,
  smd: 641,
  md: 769,
  mdlg: 901,
  lg: 1281,
  xl: 1500,
};

export const localeText: { [key: string]: string } = {
  en: 'English',
  fr: 'Français',
};

export const colorPickerHexToClassName: { [key: string]: string } = {
  '#04aa9e': 'primaryLightGreen',
  '#1a8479': 'primaryMidGreen',
  '#0d4665': 'primaryDarkGreen',
  '#ffffff': 'primaryWhite',
  '#000000': 'primaryBlack',
  '#00c1d2': 'secondaryGreen',
  '#bbaafd': 'secondaryPurple',
  '#ffc500': 'secondaryYellow',
  '#f985da': 'secondaryPink',
  '#ff913d': 'secondaryOrange',
  '#e9fafb': 'lightGreen',
  '#f1eeff': 'lightPurple',
  '#fffaeb': 'lightYellow',
  '#fef0fb': 'lightPink',
  '#fff3eb': 'lightOrange',
  '#f1f6fc': 'lightGrey',
};

export const focusableElements: string =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
