import styles from './ShareThis.module.scss';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

interface ShareThisProps {
  text?: string;
}

// Extend the Window interface
interface MyWindow extends Window {
  __sharethis__docReady?: (fn: () => void, context?: any) => void;
  __sharethis__?: {
    initialize: () => void;
  };
}

const ShareThis = ({ text }: ShareThisProps) => {
  const router = useRouter();
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    configureButtons();
    router.events.on('routeChangeComplete', handleRouteChange);
    // Add retry to re-initialize ShareThis in edge cases where init fails
    const retryInterval = setInterval(() => {
      if (document.getElementsByClassName('st-btn').length === 0) {
        if (retryCount < 3) {          
          shareThisInitialise();
          configureButtons();
          setRetryCount(retryCount + 1);
        } else {
          console.log('ShareThis failed to initialize: ' + router.asPath);
          clearInterval(retryInterval);
        }
      } else {
        clearInterval(retryInterval);
      }
    }, 250);
    return () => clearInterval(retryInterval);
  }, [router.asPath]);

  const configureButtons = () => {
    const waitForButtons = setInterval(() => {
      const buttons = document.getElementsByClassName('st-btn');
      if (buttons.length > 0) {
        clearInterval(waitForButtons);
        for (const button of buttons) {
          button.setAttribute('role', 'button');
          button.setAttribute('tabindex', '0');
          button.addEventListener(
            'keydown',
            handleButtonKeyDown as EventListener
          );
        }
      }
    }, 50);
  };

  const shareThisInitialise = () => {
    // window.__sharethis__docReady is a call back function in sharethis.js,
    // which will allow use to re-initialize sharethis buttons when needed
    const myWindow = window as MyWindow;
    if (myWindow.__sharethis__docReady) {
      myWindow.__sharethis__docReady(() => {
        myWindow.__sharethis__?.initialize();
      });
    }
  };

  const handleRouteChange = () => {
    shareThisInitialise();
    configureButtons();
  };

  const handleButtonKeyDown = (event: KeyboardEvent) => {
    const target = event.currentTarget as HTMLElement;
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      if (target.click) {
        target.click();
      }
    }
  };

  return (
    <>
      <div className={styles.shareThis__wrapper}>
        {text && <div className={styles.shareThis__title}>{text}</div>}
        <div
          className={`sharethis-inline-share-buttons ${styles.shareThis__buttons}`}
        ></div>
      </div>
      <Script
        id='shareThisScript'
        src='https://platform-api.sharethis.com/js/sharethis.js#property=64412947e412510019625a29&product=sop'
        strategy='afterInteractive'
      />
    </>
  );
};

const ShareThisWrapper = (props: ShareThisProps) => {
  const router = useRouter();
  const [key, setKey] = useState(Date.now());

  useEffect(() => {
    setKey(Date.now());
  }, [router]);

  return <ShareThis {...props} key={'share_this_' + key} />;
};

export default ShareThisWrapper;
