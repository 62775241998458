import React, { ReactNode } from 'react';

import { Footer, Header } from '@/layout';
import { PreviewIndicator } from './PreviewIndicator';

interface LayoutProps {
  className?: string;
  children: ReactNode;
  wrapperClassName?: string;
  preview?: boolean;
}

export const Layout = ({
  children,
  className,
  wrapperClassName,
  preview = false,
}: LayoutProps) => {

  return (
    <>
      <PreviewIndicator preview={preview} />
      <div className={wrapperClassName}>
        <Header />
        <main className={className}>{children}</main>
        <Footer />
      </div>
    </>
  );
};
