import styles from './AuthorCardSmall.module.scss';
import Image from 'next/image';

import type { AuthorCard } from '@/types';
import { ResponsiveImage, UIContext } from '@/ui';
import { useContext } from 'react';

const AuthorCardSmall = ({
  employeeImage,
  employeeName,
  employeeJobTitle,
}: AuthorCard) => {
  const { fallbackEmployeeProfileImage } = useContext(UIContext);

  const image = employeeImage
    ? employeeImage
    : fallbackEmployeeProfileImage;

  return (
    <div className={styles.authorContent}>
      <div className={styles.authorContent__authorImage}>        
               {image &&
            <ResponsiveImage
              media={image}
              sizes={{ sm: 48}}
              className={styles.authorContent__authorImageFile}
            />
          }
      </div>
      <div className={styles.authorContent__authorInfo}>
        <div className={styles.authorContent__authorName}>{employeeName}</div>
        <div className={styles.authorContent__authorJobTitle}>
          {employeeJobTitle}
        </div>
      </div>
    </div>
  );
};

export default AuthorCardSmall;
