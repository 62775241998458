import { mapComponent } from './ComponentsMapper';

import { ContentfulComponentType } from '@/types';


interface DynamicComponentsProps {
    components?: ContentfulComponentType[];
    data?: any;
}

const DynamicComponents = ({ components = [], data = {} }: DynamicComponentsProps) => {
    return (
        <>
            {components.map((component: ContentfulComponentType, index: number) =>
                mapComponent(component, { index, ...data })
            )}
        </>
    );
};

export default DynamicComponents;
