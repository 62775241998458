import { PagePreview } from '@/types';
import RelatedInsight from './RelatedInsight';

type RelatedInsightFromPagePreviewProps = {
  pagePreviews: PagePreview[];
};

const RelatedInsightFromPagePreview = ({
  pagePreviews,
}: RelatedInsightFromPagePreviewProps) => {
  return (
    <>
      {pagePreviews.map((pagePreview) => (
        <RelatedInsight
          key={pagePreview.sys?.id}
          slug={pagePreview.slug}
          heading={pagePreview.pageHeading}
          insightTypeReference={pagePreview.insightTypeReference}
          media={pagePreview.previewMedia}
          navigationTag={pagePreview.navigationTag}
          pageTag={pagePreview.pageTag}
        />
      ))}
    </>
  );
};

export default RelatedInsightFromPagePreview;
