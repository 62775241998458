import styles from './ComponentMarketoBlock.module.scss';

import React, { useState } from 'react';

import { ComponentCtaButton, Icon, MarketoForm, Modal } from '@/ui';
import { ComponentMarketoBlockTypes } from '@/types';
import { useScrollLock } from '@/ui/hooks';

const ComponentNewsletterSignup = ({
  sys,
  marketoHeading,
  marketoCtaText,
  marketoEmailText,
  marketoEmailExampleText,
  marketoFormId,
  marketoSuccessMessage = 'Form submitted',
}: ComponentMarketoBlockTypes) => {
  const id = `newsletter-${sys?.id}`;
  const [isOpen, setIsOpen] = useState(false);
  const [blockScroll, allowScroll] = useScrollLock();
  // Keys match Marketo fields.
  const [prePopulatedFields, setPrePopulatedFields] = useState({
    Email: '',
  });

  const handleFormOpen = () => {
    setIsOpen(true);
    blockScroll();
  };

  const handleFormClose = () => {
    setIsOpen(false);
    allowScroll();
  };

  return (
    <section className={`${styles.marketoBlock} ${styles.newsletterSignup}`}>
      <div className={styles.marketoBlock__inner}>
        <h2 className={styles.marketoBlock__heading}>{marketoHeading}</h2>
        <div className={styles.marketoBlock__formWrapper}>
          {/* React, styled version of form */}
          <div className={styles.marketoBlock__form}>
            <div className={styles.marketoBlock__formSection}>
              <label
                htmlFor={`${sys?.id}-email`}
                className={styles.marketoBlock__label}
              >
                {marketoEmailText}
              </label>
              <input
                id={`${sys?.id}-email`}
                className={styles.marketoBlock__input}
                type='text'
                placeholder={marketoEmailExampleText}
                required
                aria-describedby={`${sys?.id}-email-error`}
                onChange={(event) =>
                  setPrePopulatedFields({ Email: event.target.value })
                }
              />
            </div>
            <ComponentCtaButton
              ctaType='button'
              buttonType='submit'
              ctaText={marketoCtaText}
              ctaStyle='solid'
              ctaIcon='arrow'
              ctaIconPosition='right'
              onClick={handleFormOpen}
              tracking={{ newsletter_open: marketoFormId }}
              id={id}
            />
          </div>
          <Modal
            handleClose={() => handleFormClose()}
            isOpen={isOpen}
            triggerId={id}
          >
            <MarketoForm
              key='marketoSignup'
              formId={marketoFormId}
              prePopulatedFields={prePopulatedFields}
              successMessage={marketoSuccessMessage}
            />
          </Modal>
        </div>
      </div>
      <Icon
        id='logo-white'
        width={240}
        height={240}
        className={styles.marketoBlock__iconBg}
      />
    </section>
  );
};

export default ComponentNewsletterSignup;
