import { useEffect, useRef } from 'react';

export const useScriptField = (scriptField: string) => {
  
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {

    // Regular expression to check for <script> tags
    const scriptTagRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;

    // Check if `scriptField` contains a <script> tag
    const containsScriptTag = scriptTagRegex.test(scriptField);

    if (scriptField && !containsScriptTag){
      console.warn('The scriptField does not contain a <script> tag. Please ensure the scriptField contains a <script> tag to render the scriptField.');
      return;
    }

    if (!scriptField) return;

    // Create a script-container rendered script set a reference
    const containerDiv = document.createElement('div');
    containerDiv.setAttribute('class', 'script-container');
    containerRef.current = containerDiv;

    const parser = new DOMParser();
    const doc = parser.parseFromString(`${scriptField}`, 'text/html');
    const scripts = doc.querySelectorAll('script');

    scripts.forEach((originalScript) => {
      const script = document.createElement('script');
      [...originalScript.attributes].forEach(attr => script.setAttribute(attr.name, attr.value));

      if (!originalScript.src) {
        // For inline scripts, copy the script content
        script.textContent = originalScript.textContent;
      }
      
      containerDiv.appendChild(script);
    });

    
    document.body.appendChild(containerDiv);

    // Cleanup function to remove the script-container from document body
    return () => {
      if (containerRef.current && document.body.contains(containerRef.current)) {
        document.body.removeChild(containerRef.current);
        containerRef.current = null; // Reset the ref after removal
      }
    };
  }, [scriptField]);
};
