import styles from './Header.module.scss';

import { useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import Link from 'next/link';
import Image from 'next/image';

import { useIsMobile } from '@/ui/utils';
import { UIContext, ComponentCtaButton, SearchHero, SearchClient } from '@/ui';

import { Navigation, MobileNavigation } from '@/layout/Header/';

import TopBar from './TopBar/TopBar';

const Header = () => {
  const router = useRouter();
  const { locale } = router;
  const disabledSearchLocales = ['fr'];
  const showSearch = !disabledSearchLocales.includes(locale as string);

  const {
    logo,
    headerNavigation,
    contactUsText,
    contactUsLink,
    searchTextShort,
    menuText,
    closeText,
    searchPage,
  } = useContext(UIContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [openSubDropdown, setOpenSubDropdown] = useState<string | null>(null);

  const isMobile = useIsMobile();

  const headerRef = useRef<HTMLDivElement>(null);

  const handleSearch = () => {
    setIsMenuOpen(false);
    if (openDropdown === 'search') {
      setOpenDropdown(null);
    } else {
      setOpenDropdown('search');
    }
    setOpenSubDropdown(null);
  };

  const handleMenuClick = () => {
    setIsMenuOpen(true);
  };

  const handleCloseClick = () => {
    setIsMenuOpen(false);
    setOpenDropdown(null);
    setOpenSubDropdown(null);
  };

  useEffect(() => {
    if (isMenuOpen || openSubDropdown) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isMenuOpen, openSubDropdown]);

  useEffect(() => {
    setOpenDropdown(null);
    setOpenSubDropdown(null);
    setIsMenuOpen(false);
  }, [router]);

  return (
    <>
      <header ref={headerRef} className={styles.header}>
        <TopBar />

        <div className={styles.header__main}>
          <div className={`${styles.header__container}`}>
            <div className={styles.header__logo}>
              <Link href='/'>
                {logo?.url && (
                  <Image
                    src={logo?.url}
                    alt={logo?.title || 'OneOcean'}
                    width={155}
                    height={41}
                    priority
                  />
                )}
              </Link>
            </div>

            {!isMobile && headerNavigation && (
              <div className={styles.header__nav}>
                <Navigation
                  headerRef={headerRef}
                  menuItems={headerNavigation}
                  openDropdown={openDropdown}
                  setOpenDropdown={setOpenDropdown}
                  openSubDropdown={openSubDropdown}
                  setOpenSubDropdown={setOpenSubDropdown}
                />
              </div>
            )}

            {!isMobile && (
              <div className={styles.header__contact}>
                <ComponentCtaButton
                  ctaType='link'
                  ctaText={contactUsText}
                  ctaStyle='solid'
                  ctaSize='small'
                  ctaIcon='chevron'
                  ctaIconPosition='right'
                  ctaUrl={contactUsLink}
                  cssClass='header__contact chevron'
                />
              </div>
            )}

            {showSearch && (
              <div className={styles.header__search}>
                {isMobile && (
                  <ComponentCtaButton
                    ctaType='link'
                    ctaText={searchTextShort}
                    ctaStyle='solid'
                    ctaSize='small'
                    ctaIcon='search'
                    ctaIconPosition='right'
                    cssClass='header__search'
                    onClick={handleSearch}
                  />
                )}

                {!isMobile && (
                  <ComponentCtaButton
                    ctaType='link'
                    ctaText={searchTextShort}
                    ctaStyle='underline'
                    ctaIcon='search'
                    ctaIconPosition='right'
                    cssClass='header__search'
                    onClick={handleSearch}
                  />
                )}

                {openDropdown === 'search' && (
                  <div className={styles.header__search__subNavWrapper}>
                    <SearchClient>
                      <SearchHero
                        {...searchPage}
                        searchText={searchTextShort}
                        type='search'
                        style={{ minHeight: 'calc(100dvh - 110px)' }}
                        onCloseButtonClick={handleSearch}
                      />
                    </SearchClient>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </header>
      {isMobile && (
        <MobileNavigation
          headerRef={headerRef}
          menuItems={headerNavigation}
          openDropdown={openDropdown}
          setOpenDropdown={setOpenDropdown}
          openSubDropdown={openSubDropdown}
          setOpenSubDropdown={setOpenSubDropdown}
          showSearch={showSearch}
          menuDisplay={!!isMobile && !!isMenuOpen}
        />
      )}
      {isMobile && (
        <div className={styles.mobileMenuButtonWrapper}>
          {!isMenuOpen && (
            <ComponentCtaButton
              ctaType='button'
              ctaText={menuText}
              ctaStyle='solid'
              ctaSize='small'
              ctaIcon='menu'
              cssClass='menu'
              ctaIconPosition='right'
              onClick={handleMenuClick}
            />
          )}

          {isMenuOpen && openDropdown !== 'search' && (
            <ComponentCtaButton
              ctaType='button'
              ctaText={closeText}
              ctaStyle='solid'
              ctaSize='small'
              ctaIcon='close'
              cssClass='menu-close'
              ctaIconPosition='right'
              onClick={handleCloseClick}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Header;
