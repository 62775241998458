import styles from './TopBar.module.scss';

import { useContext, useRef } from 'react';

import { Config } from '@/ui/utils';
import { UIContext } from '@/ui';

import { LanguageSelector } from '../LanguageSelector';
import CustomerLogin from '../CustomerLogin/CustomerLogin';

const TopBar = () => {
  const regionSelector = Config.regionSelector;

  const { customerLoginLink } = useContext(UIContext);
  const topBarRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={topBarRef} className={styles.topbar}>
      <div className={`${styles.topbar__container}`}>
        <div className={styles.topbar__languageSelector}>
          {regionSelector && <LanguageSelector topBarRef={topBarRef} />}
        </div>
        <div className={styles.topbar__customerLogin}>
          {customerLoginLink && <CustomerLogin />}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
