import styles from './ComponentCardListing.module.scss';

import { CardListing, CareerVacancy, PagePreview } from '@/types';
import { ComponentCtaButton, Card } from '@/ui';

const ComponentCardListing = ({
  clHeading,
  clIntro,
  ctaButton,
  secondaryCtaText,
  secondaryCtaLink,
  cardListing,
  cardCtaText,
}: CardListing) => {
  function isVacancy(card: PagePreview | CareerVacancy): card is CareerVacancy {
    return (card as CareerVacancy).vacancyHeading !== undefined;
  }

  const returnNavigationItemCard = (card: PagePreview) => {
    const { pageHeading, previewText, slug } = card;
    return (
      <Card
        heading={pageHeading}
        link={slug}
        cardHeadingVariant={cardHeadingVariant}
        ctaText={cardCtaText}
        isSecondary
      >
        <p>{previewText}</p>
      </Card>
    );
  };

  const returnCareerVacancyCard = (card: CareerVacancy) => {
    const { vacancyHeading, vacancyBody, vacancyLink, vacancyTag } = card;

    return (
      <Card
        heading={vacancyHeading}
        link={vacancyLink}
        cardHeadingVariant={cardHeadingVariant}
        ctaText={cardCtaText}
        contentTags={vacancyTag}
        isSecondary
      >
        {!!vacancyBody?.length && <p>{vacancyBody}</p>}
      </Card>
    );
  };

  const hasHeading = !!clHeading.length;
  const cardHeadingVariant = hasHeading ? 'h3' : 'h2';
  const hasIntro = !!clIntro?.length;
  const hasCtaButton = !!ctaButton;
  const hasSecondaryCta = !!secondaryCtaText && !!secondaryCtaLink;

  return (
    <section className={styles.cardListing}>
      {(hasHeading || hasIntro || hasCtaButton || hasSecondaryCta) && (
        <div className={styles.cardListing__top}>
          {hasHeading && (
            <h2 className={styles.cardListing__heading}>{clHeading}</h2>
          )}
          {hasIntro && <p className={styles.cardListing__intro}>{clIntro}</p>}
          {(hasCtaButton || hasSecondaryCta) && (
            <div className={styles.cardListing__buttons}>
              {hasCtaButton && <ComponentCtaButton {...ctaButton} />}
              {hasSecondaryCta && (
                <span className={styles.cardListing__secondaryButton}>
                  <ComponentCtaButton
                    ctaType='link'
                    ctaText={secondaryCtaText}
                    ctaStyle='underline'
                    ctaIcon='arrow'
                    ctaIconPosition='right'
                    ctaUrl={secondaryCtaLink}                    
                  />
                </span>
              )}
            </div>
          )}
        </div>
      )}
      <ul className={styles.cardListing__grid}>
        {cardListing.map((card: PagePreview | CareerVacancy) => {
          const { sys } = card;

          return (
            <li key={sys?.id} className={styles.cardListing__card}>
              {!isVacancy(card) &&
                returnNavigationItemCard(card as PagePreview)}
              {isVacancy(card) &&
                returnCareerVacancyCard(card as CareerVacancy)}
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default ComponentCardListing;
