import styles from './Pillar.module.scss'

import type { PillarItem } from "@/types"

const Pillar = ({ pillarColour, pillarText }: PillarItem) => (
    <div className={styles.pillar} style={{ borderLeftColor: pillarColour }}>
        {pillarText && <span className={styles.pillar__heading}>{pillarText}</span>}
    </div>
);

export default Pillar;