import styles from './Footer.module.scss';

import Image from 'next/image';
import { useContext } from 'react';

import { UIContext, SocialLinks, ComponentCtaButton } from '@/ui';
import FooterNavigation from './FooterNavigation';
import FooterLegal from './FooterLegal';

import type { NavigationItem } from '@/types';

const Footer = () => {
  const {
    footerLogo,
    footerNavigation,
    footerLegalText,
    copyrightText = '',
    footerCalloutText,
    footerLegalNavigation,
    footerCta = [],
    twitterLink = '',
    youTubeLink = '',
    linkedInLink = '',
  } = useContext(UIContext);

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__container}>
        <div className={styles.footer__grid}>
          <div className={styles.footer__column}>
            <div className={styles.footer__callout}>
              <h3 className={styles.footer__calloutHeading}>
                {footerCalloutText}
              </h3>
              {footerCta.length > 0 && (
                <div className={styles.footer__calloutCta}>
                  <ComponentCtaButton
                    {...footerCta[0]}
                    ctaType='link'
                    cssClass='dark-blue'
                    ctaStyle='outline'
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.footer__column}>
            {footerNavigation && (
              <div className={styles.footer__navigationContainer}>
                {footerNavigation.map((navigationItem: NavigationItem) => (
                  <FooterNavigation
                    key={`footerNavItem_${navigationItem.sys?.id}`}
                    {...navigationItem}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.footer__container}>
        <div className={`${styles.footer__grid} ${styles.noGap}`}>
          <div className={`${styles.footer__column} ${styles.withPadding}`}>
            <div className={styles.footer__brand}>
              {footerLogo?.url && footerLogo?.title && (
                <Image
                  src={footerLogo?.url}
                  width={255}
                  height={60}
                  alt={footerLogo?.title || 'OneOcean'}
                  draggable={false}
                />
              )}
              <div className={styles.footer__socialContainer}>
                <SocialLinks
                  {...{
                    twitter: twitterLink,
                    youtube: youTubeLink,
                    linkedIn: linkedInLink,
                  }}
                />
              </div>
            </div>
          </div>
          <div className={`${styles.footer__column} ${styles.withPadding}`}>
            <div className={styles.footer__legal}>
              {footerLegalNavigation && (
                <FooterLegal
                  copyrightText={copyrightText}
                  text={footerLegalText}
                  links={footerLegalNavigation}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
