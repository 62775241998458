import styles from './TagPill.module.scss';

interface TagPillProps {
  text?: string;
  isAccent?: boolean;
  onDark?: boolean;
}

export const TagPill = ({
  text,
  isAccent = false,
  onDark = false,
}: TagPillProps) => {
  return (
    <span
      className={`${styles.tagPill} ${isAccent ? styles.generalAccent : ''} ${
        onDark ? styles.onDark : ''
      }`}
    >
      {text}
    </span>
  );
};
