import {
  PagePreview,
  DataFeaturedLink,
  RelatedInsightWrapperProps,
  RelatedInsightHeadingVariant,
} from '@/types';

function isPagePreview(
  component: PagePreview | DataFeaturedLink
): component is PagePreview {
  return 'previewText' in component;
}

type GetRelatedInsightsWrapperPropsParams = {
  component: PagePreview | DataFeaturedLink | undefined;
  heading?: string;
  headingVariant: RelatedInsightHeadingVariant;
};

export function getRelatedInsightsWrapperProps({
  component,
  heading,
  headingVariant,
}: GetRelatedInsightsWrapperPropsParams): RelatedInsightWrapperProps | null {
  if (!component) {
    return null;
  }

  if (isPagePreview(component)) {
    return {
      heading,
      headingVariant,
      pagePreviews: [component],
    };
  }

  return {
    heading,
    headingVariant,
    featuredLink: component,
  };
}
