import React, { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
// @ts-ignore
import isDarkColor from 'is-dark-color';

import { SolutionsHeroComponent, ComponentCta, PageDataTag } from '@/types';

import { colorPickerHexToClassName } from '@/ui/constants';
import { convertMarkdown, useIsMobile, UIContext } from '@/ui/utils';
import {
  ComponentCtaButton,
  Breadcrumb,
  TagPill,
  ResponsiveImage,
  ComponentStickyMenu,
} from '@/ui/';

import styles from './ComponentSolutionsHero.module.scss';

const ComponentSolutionsHero = ({
  pageData,
  heroHeading,
  heroColour = 'white',
  heroMedia,
  heroBody,
  ctas,
  showBreadcrumb = false,
}: SolutionsHeroComponent) => {
  const { tags, anchorLinks } = pageData;

  heroColour = heroColour?.toLowerCase().replace(' ', '-');

  const styleColour = colorPickerHexToClassName[heroColour]
    ? colorPickerHexToClassName[heroColour]
    : heroColour;

  const { playVideoText, pauseVideoText } = useContext(UIContext);

  const [isPlaying, setIsPlaying] = useState(true);
  const [isVideo, setIsVideo] = useState(false);

  const isMobile = useIsMobile();
  const hasMedia = heroMedia?.url;

  const isImage = heroMedia?.contentType.startsWith('image');
  const hasCtas = !!ctas?.length;

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const video = !isImage;
    if (video) {
      setIsVideo(video);
    }
  }, [heroMedia, isImage]);

  return (
    <div
      className={`${styles.solutionsHero} ${styles[styleColour]} ${
        !hasMedia ? styles['no-image'] : ''
      }`}
    >
      <div className={styles.solutionsHero__inner}>
        <div
          className={`${styles.solutionsHero__content} ${
            showBreadcrumb ? styles['breadcrumb'] : ''
          }`}
        >
          {showBreadcrumb && (
            <div className={styles.solutionsHero__breadcrumb}>
              <Breadcrumb usedOnLightBackground={!isDarkColor(heroColour)} />
            </div>
          )}

          <div className={styles.solutionsHero__solutionInfo}>
            {tags.length > 0 && (
              <div className={styles.solutionsHero__solutionTags}>
                {tags.map((tag: PageDataTag) => {
                  return (
                    <div key={tag.tagName}>
                      <TagPill text={tag.tagName} />
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <h1 className={styles.solutionsHero__title}>{heroHeading}</h1>

          {heroBody && (
            <div className={styles.solutionsHero__description}>
              {convertMarkdown(heroBody)}
            </div>
          )}

          {hasCtas && (
            <div className={styles.solutionsHero__clickthroughs}>
              {ctas.map((cta: ComponentCta) => {
                return (
                  <ComponentCtaButton
                    key={cta.ctaText}
                    ctaType='link'
                    ctaText={cta.ctaText}
                    ctaAria={cta.ctaAria}
                    ctaStyle={cta.ctaStyle}
                    ctaIcon={cta.ctaIcon}
                    ctaIconPosition={cta.ctaIconPosition}
                    ctaUrl={cta.ctaUrl}
                    ctaPage={cta.ctaPage}
                    cssClass={styleColour}
                    ctaMarketoFormId={cta.ctaMarketoFormId}
                    ctaDigitalAsset={cta.ctaDigitalAsset}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div className={styles.solutionsHero__image}>
          {isImage && (
            <>
              <div className={styles.solutionsHero__imageWrapper}>
                <ResponsiveImage
                  sizes={{ sm: 400, md: 768 }}
                  className={styles.heroImageClip__file}
                  media={heroMedia}
                  priority
                />
              </div>
              {heroMedia?.description && (
                <div className={styles.solutionsHero__caption}>
                  <span>{heroMedia?.description}</span>
                </div>
              )}
            </>
          )}
          {isVideo && (
            <div className={styles.solutionsHero__imageWrapper}>
              <button
                type='button'
                className={`${styles.solutionsHero__videoButton} ${
                  isPlaying ? styles.pause : styles.play
                }`}
                onClick={togglePlayPause}
              >
                {!isMobile && (isPlaying ? pauseVideoText : playVideoText)}
              </button>
              <ReactPlayer
                className={`${styles.solutionsHero__video_file}`}
                url={heroMedia.url}
                width='100%'
                height='100%'
                playing={isPlaying}
                muted
                loop
                playsinline
                controls={false}
              />
            </div>
          )}
        </div>
      </div>
      {anchorLinks.length > 0 && (
        <ComponentStickyMenu
          anchorLinks={anchorLinks}
          ctas={ctas}
          styleColour={styleColour}
        />
      )}
    </div>
  );
};

export default ComponentSolutionsHero;
