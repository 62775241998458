import { useContext } from 'react';
import Script from 'next/script';

import { UIContext } from '../utils/context';

export const CookiePro = () => {
  const { cookieProId } = useContext(UIContext);

  return (
    <>
      <Script
        src={`https://cookie-cdn.cookiepro.com/consent/${cookieProId}/OtAutoBlock.js`}
      />
      <Script
        src='https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js'
        data-document-language='true'
        data-domain-script={cookieProId}
      />
      <Script>{`function OptanonWrapper() { }`}</Script>
    </>
  );
};
