import { useContext } from 'react';

import { UIContext } from '@/ui';

export const useFullPath = (path: string | undefined, withPageTitle: boolean = false) => {
    const { paths } = useContext(UIContext);

    if (!path) return "";

    const segments = paths.find((item: { params: { pageTitle: string, slug: string | any[]; }; }) =>
        item.params.slug[item.params.slug.length - 1] === path.replace("/", ""));

    const fullSegment = segments ? segments?.params?.slug?.join('/') : path;

    return withPageTitle ? {
        pageTitle: segments?.params?.pageTitle,
        path: fullSegment,
    } : fullSegment;
};
