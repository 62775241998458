import SVG from 'react-inlinesvg';

export const Icon = ({
  id,
  width = 50,
  height = 50,
  className = '',
}: {
  id: string;
  width: number;
  height: number;
  className?: string;
}) => (
  <SVG
    src={`/icons/${id}.svg`}
    width={width}
    height={height}
    role='presentation'
    className={className}
    cacheRequests={true}
  />
);
