import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
    children: ReactNode;
}

export const Portal = ({ children }: PortalProps) => {
    if (typeof window === "object") {
        return createPortal(children, document.getElementById("portal")!);
    }

    return null;
}
