import styles from './SocialLinks.module.scss';

import Link from 'next/link';

import { Icon, trackGA } from '@/ui';

export const SocialLinks = ({
  youtube = '',
  linkedIn = '',
  twitter = '',
  darkIcon,
  smaller,
}: {
  youtube?: string;
  linkedIn?: string;
  twitter?: string;
  darkIcon?: boolean;
  smaller?: boolean;
}) => (
  <div
    className={`
      ${styles.socialLinks} 
      ${darkIcon ? styles['darkIcon'] : ''} 
      ${smaller ? styles['smaller'] : ''}
  `}
  >
    {linkedIn && (
      <Link
        href={linkedIn}
        title='LinkedIn'
        target='_blank'
        className={styles.socialLinks__link}
        onClick={() => trackGA('social_click', { title: 'LinkedIn' })}
      >
        <Icon id='linkedin' width={45} height={45} />
      </Link>
    )}
    {twitter && (
      <Link
        href={twitter}
        title='Twitter'
        target='_blank'
        className={styles.socialLinks__link}
        onClick={() => trackGA('social_click', { title: 'Twitter' })}
      >
        <Icon id='twitter' width={45} height={45} />
      </Link>
    )}
    {youtube && (
      <Link
        href={youtube}
        title='YouTube'
        target='_blank'
        className={styles.socialLinks__link}
        onClick={() => trackGA('social_click', { title: 'Youtube' })}
      >
        <Icon id='youtube' width={45} height={45} />
      </Link>
    )}
  </div>
);
