import Tokenizr from 'tokenizr';


export const compressQuery = (query: string) => {
  const lexer = new Tokenizr();

  /*  configure lexical analysis  */
  lexer.rule(/#[^\r\n]*(?=\r?\n)/, (ctx) => {
    ctx.accept('comment');
  });
  lexer.rule(/"(?:\\"|[^"])*"/, (ctx) => {
    ctx.accept('string');
  });
  lexer.rule(/$[a-zA-Z_][a-zA-Z0-9_]*/, (ctx) => {
    ctx.accept('var');
  });
  lexer.rule(/[a-zA-Z_][a-zA-Z0-9_]*/, (ctx) => {
    ctx.accept('id');
  });
  lexer.rule(/[+-]?[0-9]*\.?[0-9]+(?:[eE][+-]?[0-9]+)?/, (ctx) => {
    ctx.accept('number');
  });
  lexer.rule(/[ \t\r\n]+/, (ctx) => {
    ctx.accept('ws', ' ');
  });
  lexer.rule(/[{}]/, (ctx) => {
    ctx.accept('brace');
  });
  lexer.rule(/[[\]]/, (ctx) => {
    ctx.accept('bracket');
  });
  lexer.rule(/[()]/, (ctx) => {
    ctx.accept('parenthesis');
  });
  lexer.rule(/,/, (ctx) => {
    ctx.accept('comma');
  });
  lexer.rule(/!/, (ctx) => {
    ctx.accept('not');
  });
  lexer.rule(/\.\.\./, (ctx) => {
    ctx.accept('ellipsis');
  });
  lexer.rule(/@/, (ctx) => {
    ctx.accept('at');
  });
  lexer.rule(/:/, (ctx) => {
    ctx.accept('colon');
  });
  lexer.rule(/./, (ctx) => {
    ctx.accept('any');
  });
  lexer.input(query);
  lexer.debug(false);

  /*  fetch all parsed tokens  */
  const tokens = lexer.tokens();

  /*  remove whitespace tokens at harmless positions  */
  let output = '';
  const re = /^(?:brace|bracket|parenthesis|comma|colon)$/;
  for (let i = 0; i < tokens.length; i++) {
    if (
      tokens[i].type === 'comment' ||
      (tokens[i].type === 'ws' &&
        ((i < tokens.length - 1 && tokens[i + 1].type.match(re)) ||
          (i > 0 && tokens[i - 1].type.match(re))))
    ) {
      tokens.splice(i, 1);
      i--;
    }
  }

  /*  assembly and return new query string  */
  tokens.forEach((token: any) => {
    output += token.value;
  });

  return output;
};
