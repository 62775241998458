import styles from './Card.module.scss';

import { ReactNode, useContext } from 'react';
import { format, parseISO } from 'date-fns';
import { enGB, fr } from 'date-fns/locale';

import { UIContext, useIsMobile } from '@/ui/utils';
import { Asset, PageDataTag, InsightRef, NavigationTag } from '@/types';
import {
  DynamicHeading,
  NavigationTagPill,
  TagPill,
  Icon,
  DataLink,
  ResponsiveImage,
} from '@/ui';
import { useRouter } from 'next/router';

interface CardProps {
  heading: string;
  link: string;
  cardHeadingVariant: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  ctaText: string;
  image?: Asset;
  navigationTag?: NavigationTag;
  tags?: Array<PageDataTag>;
  contentTags?: Array<string>;
  children?: ReactNode;
  isSecondary?: boolean;
  useTopic?: boolean;
  insightTypeReference?: InsightRef;
  publishDate?: Date;
  insightsSmall?: boolean;
  insightsLarge?: boolean;
}

/**
 * Primary Card - Multi Card Callout, Insights Listing
 * Secondary Card - Card Listing,
 * insightsSmall & insightsLarge - Insights callout (Standard)
 */

const Card = ({
  heading,
  link,
  cardHeadingVariant,
  ctaText,
  image,
  navigationTag,
  tags,
  contentTags,
  children,
  isSecondary = false,
  useTopic = false,
  insightTypeReference,
  publishDate,
  insightsSmall = false,
  insightsLarge = false,
}: CardProps) => {
  const { locale = 'en' } = useRouter();
  const currentLocale = locale;

  const isMobile = useIsMobile();
  const { fallbackMetaDataImage } = useContext(UIContext);

  if (insightsLarge && !image) {
    image = fallbackMetaDataImage;
  }

  const hasImage = !!image?.url;
  const hasNavigationTag = !!navigationTag;
  const hasTags = !!tags?.length;
  const hasContentTags = !!contentTags?.length;

  let setSizes;
  if (insightsLarge) {
    setSizes = { sm: 400, md: 776 };
  } else {
    setSizes = { sm: 400 };
  }

  const localeFormattedDate = publishDate
    ? format(parseISO(publishDate.toString()), 'dd MMMM yyyy', {
        locale: currentLocale === 'en' ? enGB : fr,
      })
    : null;

  const formatTag = (tag: PageDataTag) => {
    const { tagName, tagType } = tag;
    return (
      <span key={tag.tagName} className={styles.card__tag}>
        {tagType} <span className={styles.card__tagInner}>{tagName}</span>
      </span>
    );
  };

  const formatPill = (tag: PageDataTag) => {
    const { tagName } = tag;
    return <TagPill text={tagName} onDark={insightsLarge} />;
  };

  let tagContent;

  if (useTopic) {
    if (insightTypeReference?.showDateInCards) {
      tagContent = publishDate && (
        <span className={styles.card__tag}>
          {insightTypeReference?.insightName}
          <span className={styles.card__tagInner}>{localeFormattedDate}</span>
        </span>
      );
    } else {
      hasTags && (tagContent = tags.length > 1 ? formatTag(tags[1]) : null);
    }
  } else {
    hasTags && (tagContent = formatTag(tags[0]));
  }

  return (
    <div
      className={`${styles.card} ${hasImage ? styles.hasImage : ''} ${
        isSecondary ? styles.isSecondary : ''
      } ${insightsLarge ? styles.insightsLarge : ''}`}
    >
      {(hasImage || hasNavigationTag) && (
        <div className={styles.card__top}>
          {hasImage && (
            <div className={styles.card__image}>
              <ResponsiveImage
                sizes={setSizes}
                className={styles.card__image}
                media={image}
              />
            </div>
          )}
          {hasNavigationTag && (
            <div
              className={`${styles.card__navTagWrapper} ${
                hasImage && styles.withImage
              }`}
            >
              <NavigationTagPill {...navigationTag} />
            </div>
          )}

          {useTopic && hasTags && (
            <div
              className={`${styles.card__navTagWrapper} ${
                hasImage && styles.withImage
              }`}
            >
              {formatPill(tags[0])}
            </div>
          )}
        </div>
      )}

      <div className={styles.card__content}>
        {useTopic && hasTags && !hasImage && (
          <div className={`${styles.card__navTagWrapper} ${styles.noImage}`}>
            {formatPill(tags[0])}
          </div>
        )}

        {insightsSmall || insightsLarge ? (
          <div className={styles.card__contentUpper}>
            <div className={styles.card__contentUpperLeft}>
              <span className={styles.card__tag}>
                {insightTypeReference?.insightName}
                {publishDate && (
                  <span className={styles.card__tagInner}>
                    {localeFormattedDate}
                  </span>
                )}
              </span>
            </div>
            {insightsLarge && hasTags && !isMobile && (
              <div className={styles.card__contentUpperRight}>
                {formatPill(tags[0])}
              </div>
            )}
          </div>
        ) : (
          !!tagContent && (
            <div className={styles.card__tagWrapper}>{tagContent}</div>
          )
        )}

        <DynamicHeading
          variant={cardHeadingVariant}
          className={styles.card__heading}
        >
          <DataLink url={link} className={styles.card__headingLink}>
            {heading}
          </DataLink>
        </DynamicHeading>

        {insightsSmall && hasTags && (
          <div className={`${styles.card__contentTags}`}>
            {formatPill(tags[0])}
          </div>
        )}

        {isMobile && hasTags && insightsLarge && (
          <div className={`${styles.card__contentTags}`}>
            {formatPill(tags[0])}
          </div>
        )}

        <div className={`${styles.card__copy}`}>{children}</div>

        {hasContentTags && (
          <ul className={styles.card__contentTags}>
            {contentTags.map((tag) => (
              <li key={tag}>
                <TagPill text={tag} />
              </li>
            ))}
          </ul>
        )}

        <div className={styles.card__ctaWrapper}>
          <DataLink url={link} tabIndex={-1} className={styles.card__cta}>
            {ctaText}
            <Icon id='chevron-arrow' width={11} height={11} />
          </DataLink>
        </div>
      </div>
    </div>
  );
};

export default Card;
