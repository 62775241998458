import styles from './ComponentRichText.module.scss';

import { TinyMCERichText } from '@/ui/base';

export interface ComponentRichTextProps {
  richTextBody: string;
  isStandalone?: boolean;
}

const ComponentRichText = ({
  richTextBody,
  isStandalone = true,
}: ComponentRichTextProps) => {
  return (
    <div
      className={`${styles.richTextWrapper} ${
        isStandalone ? styles.standalone : ''
      }`}
    >
      <TinyMCERichText
        text={richTextBody}
        className={styles.richTextWrapper__inner}
      />
    </div>
  );
};

export default ComponentRichText;
