import styles from "./FooterLegal.module.scss";

import { useEffect, useState } from "react";

import type { DataNavItem, NavigationItem } from "@/types";
import { DataLink } from "@/ui/";

const FooterLegal = ({
  copyrightText,
  text,
  links,
}: {
  copyrightText?: string;
  text: string;
  links: NavigationItem[];
}) => {
  const [copyrightToken, setCopyrightToken] = useState<string>('');
  useEffect(() => {
    const currentYear: number = new Date().getFullYear();
    const copySymbol: string = String.fromCharCode(169);

    setCopyrightToken(`${copySymbol} ${currentYear} ${copyrightText ?? ''}`);
  }, [copyrightText]);

  return (
    <div className={styles.footerLegal}>
      <span className={styles.footerLegal__text}>{text}</span>
      <span className={styles.footerLegal__copyright}>{copyrightToken}</span>
      {links && (
        <ul className={styles.footerLegal__items}>
          {links.map(
            ({ sys, navUrl, navText }: DataNavItem) =>
              navUrl &&
              navText && (
                <li
                  key={`footerLegal_${sys.id}`}
                  className={styles.footerLegal__item}
                >
                  <DataLink url={navUrl} className={styles.footerLegal__link}>
                    {navText}
                  </DataLink>
                </li>
              )
          )}
        </ul>
      )}
    </div>
  );
};

export default FooterLegal;
