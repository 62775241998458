import styles from './ComponentSearchWrapper.module.scss';

import { Fragment, useEffect, useState } from 'react';
import {
  useClearRefinements,
  useInstantSearch,
  useRefinementList,
} from 'react-instantsearch-hooks-web';
import { RefinementItem } from 'react-instantsearch-dom';

import { SearchItem } from '../SearchItem';
import { SearchPagination } from '../SearchPagination';
import { ComponentInsightsFilter } from '../../ComponentInsights/ComponentInsightsFilter';
import { DataLink } from '@/ui';

interface ComponentSearchWrapperProps {
  viewCtaText: string;
  noResultsText: string;
  resultsForText: string;
  popularPages: any[];
  popularPagesText: string;
}

const ComponentSearchWrapper = ({
  pageData,
  pageTags: tags,
  insightTypes,
}: any) => {
  const {
    resultsForText,
    viewCtaText,
    noResultsText,
    popularPagesText,
    popularPages,
  } = pageData;
  const { results: searchResults } = useInstantSearch();

  const [filters, setFilters] = useState({
    pageTags: [],
    insightTypes: [],
  });

  const typeTagRefinement = useRefinementList({ attribute: 'typeTag.pluralName' });
  const topicTagRefinement = useRefinementList({ attribute: 'pageTags' });
  const { refine: clearfinements } = useClearRefinements({
    includedAttributes: ['typeTag.pluralName', 'pageTags'],
  });
  

  let pageTags = topicTagRefinement?.items?.map(
    ({ label, value }: RefinementItem<any>): any => ({ id: value, tagName: label })
  );

  type TopicTag = {
    id: string;
    text: string;
  };

  const handleFilterChange = (
    newFilters: string | string[],
    newTags: Array<TopicTag>,
    newPage?: string
  ) => {
    if (newFilters === 'All') {
      newFilters = [];
    }
    setFilters((prev: any) => {
      return {
        page: newPage ? newPage : prev.page,
        pageTags: newTags
          ? newTags.map((t: TopicTag) => t.text)
          : prev.pageTags,
        insightTypes: newFilters ? [newFilters] : prev.insightTypes,
      };
    });
  };
  useEffect(() => {
    const filtersApplied =
      filters.insightTypes.length > 0 || filters.pageTags.length > 0;
    if (filtersApplied) {
      filters.insightTypes.forEach((item: string) => {
        clearfinements();
        typeTagRefinement.refine(item);
      });
      filters.pageTags.forEach((item: string) => {
        clearfinements();
        topicTagRefinement.refine(item);
      });
    } else {
      clearfinements();
    }
  }, [filters]);

  // checking if the query length is >= 3
  // (since 3 is the minimum Algolia query length)
  const validQuery = searchResults.query && searchResults.query.length >= 3;
  return (
    <>
      {validQuery ? (
        <div className={styles.searchContainer}>
          <ComponentInsightsFilter
            pageData={pageData}
            topics={pageTags}
            tags={insightTypes}
            onChangeFilters={handleFilterChange}
            formatted
          />
          <div className={styles.searchContainer__summary}>
            {searchResults?.nbHits} {resultsForText.toLowerCase()}{' '}
            <span
              className={styles.searchContainer__summary__query}
            >{`"${searchResults?.query?.replaceAll('+', ' ')}"`}</span>
          </div>
          {searchResults?.hits.length > 0 ? (
            <>
              {searchResults.hits.map((hit: any) => {
                const { objectID, slug } = hit;
                return (
                  <Fragment key={`header-search-${objectID}`}>
                    {slug ? (
                      <DataLink url={slug} locale={false}>
                        <SearchItem {...hit} viewCtaText={viewCtaText} />
                      </DataLink>
                    ) : (
                      <SearchItem {...hit} viewCtaText={viewCtaText} />
                    )}
                  </Fragment>
                );
              })}
              <SearchPagination
                showPrevious
                showNext
                nbHits={searchResults?.hits?.length}
                nbHitsTotal={searchResults?.nbHits}
              />
            </>
          ) : (
            <div className={styles.searchContainer__noResults}>
              <div className={styles.searchContainer__noResults__message}>
                {noResultsText}
              </div>
              <div className={styles.searchContainer__noResults__links}>
                <div
                  className={styles.searchContainer__noResults__links__heading}
                >
                  {popularPagesText}
                </div>
                {popularPages?.map(
                  ({
                    pageHeading,
                    slug,
                  }: {
                    pageHeading: string;
                    slug: string;
                  }) => (
                    <DataLink url={slug} key={`popular-pages-${pageHeading}`}>
                      {pageHeading}
                    </DataLink>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default ComponentSearchWrapper;
