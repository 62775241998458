import styles from './HeaderNavItem.module.scss';

import { useCallback, useContext, useEffect, useRef } from 'react';

import { useIsMobile } from '@/ui/utils';
import { NavigationItem, HeaderNavItemProps } from '@/types';
import { ComponentCtaButton, UIContext } from '@/ui';

import SubNavItem from '../SubNavigation/SubNavItem';

const HeaderNavItem = ({
  navText,
  navUrl,
  navDescription,
  navOverarchCta,
  navOverarchCtaTextOverride,
  navChildren,
  headerRef,
  isOpen,
  isToggle,
  openSubDropdown,
  setOpenSubDropdown,
}: HeaderNavItemProps & {
  headerRef: React.RefObject<HTMLDivElement>;
}) => {
  const isMobile = useIsMobile();

  const { solutionsForText } = useContext(UIContext);

  const subNavRef = useRef<HTMLDivElement>(null);

  const mobileMenuScrollTop = () => {
    const mainMenuScrollTo = document.querySelector('.main_menu_scrollto');
    mainMenuScrollTo?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const handleSubDropdown = (sub_id: string) => {
    setOpenSubDropdown(openSubDropdown === sub_id ? null : sub_id);
  };

  const handleCloseSubNavs = () => {
    setOpenSubDropdown(null);
    isToggle();
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        subNavRef.current &&
        event.target instanceof Node &&
        !subNavRef.current.contains(event.target) &&
        headerRef?.current &&
        !headerRef.current.contains(event.target)
      ) {
        event.stopPropagation();
        isToggle();
      }
    },
    [isToggle, subNavRef, headerRef]
  );

  useEffect(() => {
    if (!isMobile) {
      if (isOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [openSubDropdown, handleClickOutside, isMobile, isOpen]);

  useEffect(() => {
    if (!isMobile) {
      if (isOpen && navChildren?.length) {
        setOpenSubDropdown(navChildren[0].sys?.id ?? null);
      }
    }
  }, [isOpen, navChildren, isMobile, setOpenSubDropdown]);

  let setCssClass = '';

  if (!isMobile) {
    setCssClass = isOpen ? 'chevron_down active' : 'chevron_down';
  }

  return (
    <>
      <li
        className={`${styles.headerNavItem} ${
          isMobile ? styles['mobile'] : ''
        } ${isOpen ? styles['nav_open'] : ''}`}
      >
        <ComponentCtaButton
          ctaType='link'
          ctaText={`${navText}`}
          ctaStyle={isMobile ? 'mobile_nav' : 'header_underline'}
          ctaUrl={navChildren && navChildren.length > 0 ? '' : navUrl}
          {...(navChildren &&
            navChildren.length > 0 && {
              ctaIcon: isMobile ? 'arrow' : 'chevron_down',
              cssClass: setCssClass,
              ctaIconPosition: 'right',
            })}
          onClick={
            navChildren && navChildren.length > 0 && isToggle !== undefined
              ? (event) => {
                  event.stopPropagation();
                  mobileMenuScrollTop();
                  handleCloseSubNavs();
                }
              : undefined
          }
          tracking={{ nav_click_header: navText }}
        />
      </li>

      <div
        ref={subNavRef}
        className={`${
          isOpen && navChildren ? styles['open'] : styles['closed']
        } ${styles.headerNavItem__subNavWrapper} ${
          openSubDropdown !== null ? styles['subnav_open'] : ''
        }`}
      >
        {navChildren && navChildren.length > 0 && (
          <div className={`${styles.subNav}`}>
            <div className={`${styles.subNav__header}`}>
              <div className={`${styles.subNav__headerInner}`}>
                <div className={`${styles.subNav__headerLeft}`}>
                  {isMobile && (
                    <div className={`${styles.subNav__headerMobileTitle}`}>
                      {navText}
                    </div>
                  )}
                  <div className={`${styles.subNav__headerTitle}`}>
                    {navDescription ? navDescription : ''}
                  </div>
                </div>
                <div className={`${styles.subNav__headerRight}`}>
                  {navOverarchCta && (
                    <ComponentCtaButton
                      ctaType='link'
                      ctaText={
                        navOverarchCtaTextOverride
                          ? navOverarchCtaTextOverride
                          : navOverarchCta.pageHeading
                      }
                      ctaStyle='solid'
                      ctaUrl={navOverarchCta.slug}
                      ctaIcon='arrow'
                      ctaIconPosition='right'
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={`${styles.subNav__main}`}>
              <div className={styles.subNav__mainTop}>
                <div className={styles.subNav__mainTopInner}>
                  <div className={styles.subNav__mainTopLeft}>
                    {solutionsForText}:
                  </div>
                  <div className={styles.subNav__mainTopRight}>
                    {navChildren?.map((subItem: NavigationItem) => {
                      return (
                        <SubNavItem
                          sys={subItem.sys?.id}
                          key={`subNavItem_${subItem.sys?.id}`}
                          navTitle={subItem.navTitle}
                          navText={subItem.navText}
                          navDescription={subItem.navDescription}
                          navUrl={subItem.navUrl}
                          navChildren={subItem.navChildren}
                          navOverarchCta={subItem.navOverarchCta}
                          navOverarchCtaTextOverride={
                            subItem.navOverarchCtaTextOverride
                          }
                          navFeaturedLink={subItem.navFeaturedLink}
                          isSubOpen={openSubDropdown === subItem.sys?.id}
                          isSubToggle={() => handleSubDropdown(subItem.sys?.id)}
                          mobileMenuScrollTop={mobileMenuScrollTop}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderNavItem;
