import styles from './PreviewIndicator.module.scss';
import Link from 'next/link';

import {sourceSansPro}  from '@/ui/';

interface PreviewIndicatorProps {
  preview: boolean;  
}

const PreviewIndicator = ({preview}: PreviewIndicatorProps) => {
  if (!preview) {
    return null;
  }
  return (    
    <div className={`${styles.preview_indicator} ${sourceSansPro.className}`}>
      <Link className={styles.preview_indicator__link} href='/api/exit-preview' passHref>
        Exit Preview
      </Link>
    </div>
  );
};

export default PreviewIndicator;
