import { GetStaticPaths } from 'next';

import { runGraphQLQuery } from './api';
import type { IPage } from "@/types";

export const getSlugQuery = (locale: string, name: string, limit?: number, preview = false) => {

  let previewTrue = preview ? 'preview: true' : '';

  return (
    `query {
      ${name}Collection(
          ${limit ? `limit: ${limit}` : ''}
          ${previewTrue}
          locale: "${locale}"
          ) {
          items {
            __typename
            sys { 
              id
            }
            slug
            pageTitle
            pageHeading
            includeInSiteMap
            parentPage {
              ... on PageStandardPage {
                pageTitle
                slug
                includeInSiteMap
              }
              ... on PageInsightsListing {
                pageTitle
                slug
              }
            }
          }
        }
      }
    `
  )

};

// Builds up an array of urls by traversing up through the parentPage field
export const buildPathSegments = ({ slug, parentPage }: IPage, item: any) => {
  // Add the current page slug to the segments array.
  let segments: Array<string> = [slug];

  // Check that the current page has a parent page
  if (parentPage?.slug) {
    const { slug: parentPageSlug } = parentPage;

    // Insights page template has no parent page so set the segment array to a single level ['insights', 'example-url'].
    if (parentPage?.slug === 'insights') {
      segments = [parentPage?.slug, ...segments];
    } else {
      // Set the current parent page
      let currentParentPage = parentPageSlug;

      // Map over all standard pages to match the current page to the parent page slug and add to the segments array.
      // This is run until there is no parent page to match.
      do {
        item.map((p: IPage) => {
          if (p.slug === currentParentPage) {

            segments = [p.slug, ...segments];
            currentParentPage = p?.parentPage?.slug || "";

            return;
          }
        });
      }
      while (!!currentParentPage);
    }
  }

  return segments;
}

const getStaticPathsBySlug = async (queries: any, collectionKey: string) => {
  const data = await Promise.all(queries.map(async ({ query, locale }: any) => await runGraphQLQuery(query, locale)));

  const paths = data.map(item => {
    const { locale } = item;

    return item[collectionKey]
      .filter((page: IPage) => page.slug)
      .map((page: IPage) => {
        const segments = buildPathSegments(page, item[collectionKey]);
        
        return ({
          params: {
            slug: segments,
            fullPath: segments.join('/'),
            pageTitle: page?.pageHeading,
            includeInSiteMap: page.includeInSiteMap,
            id: page?.sys?.id,
          }, locale
        })
      })
  }).flat();

  return { paths, fallback: 'blocking' as const };
};

const getStandardPageSlugsQuery = (locales: any, preview = false) => {

  return locales.map((locale: string) => {
    return {
      query: getSlugQuery(locale, 'pageStandardPage', 250, preview),
      locale
    };
  });
};

export const getStandardPageStaticPaths: GetStaticPaths = async ({ locales }) => {
  return await getStaticPathsBySlug(getStandardPageSlugsQuery(locales), 'pageStandardPage');
};