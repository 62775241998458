import styles from './SearchHero.module.scss';

import { Fragment } from 'react';

import { SearchPageType } from '@/types';
import { SearchBox } from '../SearchBox';
import { DataLink, ComponentCtaButton } from '@/ui';
interface SearchHeroProps extends SearchPageType {
  searchText: string;
  type: 'search' | 'page';
  onCloseButtonClick?: () => void;
}

export const SearchHero = (props: SearchHeroProps) => {
  const {
    slug,
    pageHeading,
    popularPagesText,
    popularPages,
    resultsForText,
    searchAgainText,
    searchText,
    type,
    onCloseButtonClick
  } = props;

  return (
    <div className={`${styles.searchHero} ${type === 'search' && styles.overlay}`}>
      {type === 'search' && (
        <div className={styles.searchHero__closeButton}>
          <ComponentCtaButton
            ctaType='button'
            ctaStyle='outline'
            ctaText='Close'
            ctaIcon='close'
            cssClass='dark-blue'
            onClick={onCloseButtonClick}
          />
        </div>
      )}
      <div className={styles.searchHero__heading}>{pageHeading}</div>
      <div className={styles.searchHero__input}>
        <SearchBox
          type={type}
          slug={slug}
          resultsForText={resultsForText}
          searchText={type === 'search' ? searchText : searchAgainText}
        />
      </div>
      {type === 'search' && (
        <div className={styles.searchHero__links}>
          <div className={styles.searchHero__links__heading}>
            {popularPagesText}
          </div>
          {popularPages?.map(
            ({ pageHeading, slug }: { pageHeading: string; slug: string }) => (
              <Fragment  key={`popular-pages-hero-${pageHeading}`}>{slug &&
              <DataLink url={slug}>
                {pageHeading}
              </DataLink>}
              </Fragment>
            )
          )}
        </div>
      )}
    </div>
  );
};
