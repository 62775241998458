import Image from 'next/image';

import { breakpoints } from '@/ui/constants';

interface ImageSizes {
  [breakpointKey: string]: number;
}

export const ResponsiveImage = ({
  media,
  sizes,
  className = '',
  priority = false,
  onClick,
}: {
  media: any;
  sizes: ImageSizes;
  className: string;
  priority?: boolean;
  onClick?: (e: React.MouseEvent<HTMLImageElement>) => void;
}) => {
  const { url: imageUrl } = media;
  const mobileImage = `${imageUrl}?w=${sizes['sm']}` || imageUrl;
  mobileImage !== imageUrl && delete sizes.sm;

  return (
    <picture>
      {Object.keys(sizes)
        .map((key: string) => (
          <source
            key={key}
            srcSet={`${imageUrl}?w=${sizes[key]}&fm=webp`}
            media={`(min-width: ${breakpoints[key]}px)`}
          />
        ))
        .reverse()}
      <Image
        alt={media.description ?? ''}
        className={className}
        fill
        priority={priority}
        src={`${mobileImage}&fm=webp`}
        unoptimized
        draggable='false'
        loading={priority ? 'eager' : 'lazy'}
        onClick={onClick}
      />
    </picture>
  );
};
