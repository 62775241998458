import React from 'react';

// @ts-ignore
import isDarkColor from 'is-dark-color';

import { StandardHeroComponent, ComponentCta } from '@/types';

import { colorPickerHexToClassName } from '@/ui/constants';
import { convertMarkdown } from '@/ui/utils';
import { Breadcrumb, ComponentCtaButton } from '@/ui/';

import styles from './ComponentInsightsHero.module.scss';

const ComponentInsightsHero = ({
  heroHeading,
  heroColour,
  heroSubheading,
  heroBody,
  ctas,
  showBreadcrumb = false,
}: StandardHeroComponent) => {
  heroColour = heroColour?.toLowerCase().replace(' ', '-');

  const styleColour = colorPickerHexToClassName[heroColour]
    ? colorPickerHexToClassName[heroColour]
    : heroColour;

  const hasCtas = !!ctas?.length;

  return (
    <div
      className={`${styles.insightsHero} ${styles[styleColour]} ${styles['no-image']}`}
    >
      <div className={styles.insightsHero__inner}>
        <div
          className={`${styles.insightsHero__content} ${
            showBreadcrumb ? styles['breadcrumb'] : ''
          }`}
        >
          {showBreadcrumb && (
            <div className={styles.insightsHero__breadcrumb}>
              <Breadcrumb usedOnLightBackground={isDarkColor(heroColour)} />
            </div>
          )}
          <span className={styles.insightsHero__subtitle}>
            {heroSubheading}
          </span>

          <h1 className={styles.insightsHero__title}>{heroHeading}</h1>

          {heroBody && (
            <div className={styles.insightsHero__description}>
              {convertMarkdown(heroBody)}
            </div>
          )}

          {hasCtas && (
            <div className={styles.insightsHero__clickthroughs}>
              {ctas.map((cta: ComponentCta) => {
                return (
                  <ComponentCtaButton
                    key={cta.ctaText}
                    ctaType='link'
                    ctaText={cta.ctaText}
                    ctaAria={cta.ctaAria}
                    ctaStyle={cta.ctaStyle}
                    ctaIcon={cta.ctaIcon}
                    ctaIconPosition={cta.ctaIconPosition}
                    ctaUrl={cta.ctaUrl}
                    ctaPage={cta.ctaPage}
                    cssClass={styleColour}
                    ctaMarketoFormId={cta.ctaMarketoFormId}
                    ctaDigitalAsset={cta.ctaDigitalAsset}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ComponentInsightsHero;
