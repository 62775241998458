import styles from './PromotedContentBlock.module.scss';

import { Asset } from '@/types';
import { ResponsiveImage } from '@/ui';

type PromotedContentBlockProps = {
  url: string;
  image: Asset;
  heading?: string;
  subHeading: string;
  openInNewTab?: boolean;
};

const PromotedContentBlock = ({
  heading,
  subHeading,
  url,
  image,
  openInNewTab,
}: PromotedContentBlockProps) => {
  return (
    <a
      className={styles.promotedContentBlock}
      href={url}
      target={openInNewTab ? '_blank' : '_self'}
    >
      <div className={styles.promotedContentBlock__image}>
        <ResponsiveImage
          className={styles.promotedContentBlock__image}
          sizes={{ sm: 250 }}
          media={image}
        />
      </div>

      <div className={styles.promotedContentBlock__content}>
        {heading && (
          <h6 className={styles.promotedContentBlock__heading}>{heading}</h6>
        )}

        <p className={styles.promotedContentBlock__subHeading}>{subHeading}</p>
      </div>
    </a>
  );
};

export default PromotedContentBlock;
