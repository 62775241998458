import styles from './RelatedInsightWrapper.module.scss';

import { useContext } from 'react';

import { DynamicHeading } from '@/ui';
import { UIContext } from '@/ui/utils';
import RelatedInsightFromPagePreview from './RelatedInsightFromPagePreview';
import RelatedInsightFromFeaturedLink from './RelatedInsightFromFeaturedLink';

import { RelatedInsightWrapperProps } from '@/types';

const RelatedInsightWrapper = ({
  featuredLink,
  pagePreviews = [],
  heading,
  headingVariant,
}: RelatedInsightWrapperProps) => {
  const { dataDrivenInsightText } = useContext(UIContext);

  const title =
    heading ?? featuredLink?.featuredHeading ?? dataDrivenInsightText;
  const showHeading = !!title?.length;

  return (
    <aside className={styles.relatedInsightWrapper}>
      {showHeading && (
        <DynamicHeading
          variant={headingVariant}
          className={styles.relatedInsightWrapper__title}
        >
          {title}
        </DynamicHeading>
      )}

      {pagePreviews.length > 0 && (
        <RelatedInsightFromPagePreview pagePreviews={pagePreviews} />
      )}

      {featuredLink && (
        <RelatedInsightFromFeaturedLink featuredLink={featuredLink} />
      )}
    </aside>
  );
};

export default RelatedInsightWrapper;
