import styles from './TagPill.module.scss';

import { NavigationTag } from '@/types';

export const NavigationTagPill = ({
  tagText,
  tagBgHex,
  tagFgHex,
}: NavigationTag) => {
  return (
    <span
      className={`${styles.tagPill} ${styles.navigationPill}`}
      style={{ backgroundColor: tagBgHex, color: tagFgHex }}
    >
      {tagText}
    </span>
  );
};
