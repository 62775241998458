import React from 'react';

interface DynamicTitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export const DynamicHeading = ({ variant, ...props }: DynamicTitleProps) => {
  const Heading = ({ ...props }: React.HTMLAttributes<HTMLHeadingElement>) =>
    React.createElement(variant, props, props.children);

  return <Heading {...props} />;
};
