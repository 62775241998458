import styles from './SubNavItem.module.scss';

import React, { useContext } from 'react';

import { useIsMobile } from '@/ui/utils';
import type { NavigationItem } from '@/types';
import { ComponentCtaButton, NavigationTagPill, UIContext } from '@/ui';
import PromotedContentBlock from './PromotedContentBlock/PromotedContentBlock';

const SubNavItem = ({
  navText,
  navUrl,
  navDescription,
  navOverarchCta,
  navOverarchCtaTextOverride,
  navChildren,
  navFeaturedLink,
  isSubOpen,
  isSubToggle,
  mobileMenuScrollTop,
}: NavigationItem & {
  isSubOpen: boolean;
  isSubToggle: () => void;
  mobileMenuScrollTop: () => void;
}) => {
  const isMobile = useIsMobile();
  const { exploreText, overviewText } = useContext(UIContext);

  let setCssClass = '';

  if (!isMobile) {
    setCssClass = isSubOpen ? 'chevron_down active' : 'chevron_down';
  } else {
    setCssClass = 'subNav_text';
  }

  return (
    <>
      <li className={styles.headerSubNavItem}>
        <ComponentCtaButton
          ctaType='link'
          ctaText={`${navText}`}
          ctaStyle={isMobile ? 'mobile_nav' : 'header_underline'}
          ctaUrl={navChildren && navChildren.length > 0 ? '' : navUrl}
          {...(navChildren &&
            navChildren.length > 0 && {
              ctaIcon: isMobile ? 'arrow' : 'chevron_down',
              cssClass: setCssClass,
              ctaIconPosition: 'right',
            })}
          onClick={
            navChildren && navChildren.length > 0 && isSubToggle !== undefined
              ? (event) => {
                  event.stopPropagation();
                  mobileMenuScrollTop();
                  isSubToggle();
                }
              : undefined
          }
          tracking={{ nav_click_sub: navText }}
        />
      </li>

      <div
        className={`${styles.subNavItem__subNavWrapper} ${
          isSubOpen && navChildren ? styles['open'] : styles['closed']
        }`}
      >
        <div className={styles.subNavItem}>
          <div className={styles.subNavItem__container}>
            <div className={styles.subNavItem__inner}>
              <div className={styles.subNavItem__NavInfo}>
                <div className={styles.subNavItem__tag}>
                  {navOverarchCta?.navigationTag && (
                    <NavigationTagPill {...navOverarchCta.navigationTag} />
                  )}
                </div>

                <div className={styles.subNavItem__title}>
                  {navOverarchCta?.pageHeading}
                </div>

                <div className={styles.subNavItem__text}>
                  {navDescription ? navDescription : ''}
                </div>

                <div className={styles.subNavItem__cta}>
                  <ComponentCtaButton
                    ctaType='link'
                    ctaText={
                      navOverarchCtaTextOverride
                        ? navOverarchCtaTextOverride
                        : exploreText
                    }
                    ctaStyle='outline'
                    ctaIcon='arrow'
                    ctaIconPosition='right'
                    ctaUrl={navOverarchCta?.slug ? navOverarchCta.slug : navUrl}
                    tracking={{
                      nav_overarch: navOverarchCtaTextOverride
                        ? navOverarchCtaTextOverride
                        : exploreText,
                    }}
                  />
                </div>

                {navFeaturedLink && (
                  <PromotedContentBlock
                    heading={navFeaturedLink.featuredHeading}
                    subHeading={navFeaturedLink.featuredDescription}
                    url={
                      navFeaturedLink.featuredUrl ??
                      navFeaturedLink.featuredPage.slug
                    }
                    openInNewTab={!!navFeaturedLink.featuredUrl}
                    image={navFeaturedLink.featuredImage}
                  />
                )}
              </div>

              {navChildren?.map((subChild: NavigationItem, index: number) => (
                <div
                  key={subChild.sys.id}
                  className={`${styles.subNavItem__subNav} ${
                    styles[`subNavItem__Nav${index}`]
                  }`}
                >
                  <div className={styles.subNavItem__subNavTitle}>
                    {subChild.navText}
                  </div>

                  <div className={styles.subNavItem__subNavDescription}>
                    {subChild.navDescription}
                  </div>
                  <div className={styles.subNavItem__subNavCta}>
                    <ComponentCtaButton
                      ctaType='link'
                      ctaText={overviewText}
                      ctaStyle='underline'
                      ctaIcon='arrow'
                      ctaSize='small'
                      ctaIconPosition='right'
                      ctaUrl={
                        subChild.navOverarchCta?.slug
                          ? subChild.navOverarchCta.slug
                          : subChild.navUrl
                      }
                      cssClass='header__subSubNav'
                      tracking={{ nav_click: overviewText }}
                    />
                  </div>
                  <div className={styles.subNavItem__navLinks}>
                    {subChild.navChildren?.map((subItem: NavigationItem) => (
                      <ComponentCtaButton
                        key={subItem.sys.id}
                        ctaType='link'
                        ctaText={subItem.navText}
                        ctaStyle='underline'
                        ctaIcon={!isMobile ? 'arrow' : ''}
                        ctaSize='small'
                        ctaIconPosition={!isMobile ? 'right' : ''}
                        ctaUrl={subItem.navOverarchCta?.slug ?? subItem.navUrl}
                        cssClass={isMobile ? 'no_border' : ''}
                        tracking={{ nav_click: subItem.navText }}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubNavItem;
