import React, { createContext, useContext, useEffect, useState } from 'react';
import { useWindowSize } from '../hooks';

const MobileContext = createContext<boolean | null>(null);

export const useIsMobile = (): boolean | null => {
  return useContext(MobileContext);
}

interface MobileProviderProps {
  children: any;
}

export const MobileProvider: React.FC<MobileProviderProps> = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean | null>(null);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize.width && windowSize.width <= 767) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize]);

  return (
    <MobileContext.Provider value={isMobile}>
      {children}
    </MobileContext.Provider>
  );
}