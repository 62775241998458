import styles from './Pillars.module.scss';

import Pillar from './Pillar';
import type { PillarItem } from '@/types';

export const Pillars = ({ pillars }: { pillars: PillarItem[] }) => (
  <ul className={styles.pillars}>
    {pillars.map((p) => (
      <li className={styles.pillars__item} key={p.sys.id}>
        <Pillar {...p} />
      </li>
    ))}
  </ul>
);
