import { InstantSearch } from "react-instantsearch-hooks-web";
import { useContext } from 'react';

import { UIContext } from "@/ui";

export const SearchClient = ({ children }: { children: JSX.Element}  ) => {
    const { algoliaClient, indexName } = useContext(UIContext);

    return (
      <InstantSearch
          searchClient={algoliaClient}
          indexName={indexName}
      >
          {children}
      </InstantSearch>
    )
}