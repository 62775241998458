import '@/styles/globals.scss';

import App, { AppContext, AppProps } from 'next/app';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { GoogleTagManager } from '@next/third-parties/google';

import { ContentfulLivePreview } from '@contentful/live-preview';

import { useEffect } from 'react';
import algoliasearch from 'algoliasearch';

import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react';

import { UIContextProviderProps } from '@/types';
import { CookiePro, UIContext, sourceSansPro } from '@/ui';
import { Layout } from '@/layout/Layout';
import { MobileProvider } from '@/ui/utils/MobileProvider';

const { publicRuntimeConfig } = getConfig();

function isSafari() {
  if (typeof window !== 'undefined') {
    return (
      /Safari/.test(window.navigator.userAgent) &&
      !/Chrome/.test(window.navigator.userAgent)
    );
  }
  return false;
}

const {
  NEXT_PUBLIC_ALGOLIA_INDEX,
  NEXT_PUBLIC_ALGOLIA_APP_ID,
  NEXT_PUBLIC_ALGOLIA_ADMIN_KEY,
} = publicRuntimeConfig;

const algoliaClient = algoliasearch(
  NEXT_PUBLIC_ALGOLIA_APP_ID ?? '',
  NEXT_PUBLIC_ALGOLIA_ADMIN_KEY ?? ''
);

function OneOceanApp({ Component, pageProps }: AppProps) {
  const { baseData, paths, locale, isPreviewMode } = pageProps;
  const contextData: UIContextProviderProps = {
    ...baseData,
    paths,
    locale,
    algoliaClient,
    indexName: NEXT_PUBLIC_ALGOLIA_INDEX,
  };
  const router = useRouter();
  const { gtmId, gtmEnvironment, gtmAuth, marketoMunchkinId } = contextData;

  useEffect(() => {
    if (isSafari()) {
      document.body.classList.add('safari');
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.toString() !== '') {
      const params: { [key: string]: string } = {};
      urlParams.forEach((value: string, key: string) => {
        params[key] = value;
      });

      // if key of aliId exists, do not store it in a cookie - as form already submitted
      if (params.aliId) {
        return;
      }

      // if key of sfcid exists, store it in a cookie
      document.cookie = `urlParams=${encodeURIComponent(
        JSON.stringify(params)
      )}; path=/;`;
    }

    // Load and initialize Munchkin
    if (!window.Munchkin) {
      const script = document.createElement('script');
      script.src = `//munchkin.marketo.net/munchkin.js`;
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => {
        setTimeout(() => {
          window.Munchkin.init(marketoMunchkinId);
        }, 10);
      };
    }
  }, [router.asPath, marketoMunchkinId]);

  useEffect(() => {
    // Track page views on route change
    const handleRouteChange = (url: string) => {
      if (window.Munchkin) {
        window.Munchkin.munchkinFunction('visitWebPage', { url });
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const previewLocale = locale || 'en';

  ContentfulLivePreview.init({ locale: previewLocale });

  function ComponentWrapper() {
    if (isPreviewMode) {
      return (
        <ContentfulLivePreviewProvider locale={previewLocale}>
          <Component {...pageProps} />
        </ContentfulLivePreviewProvider>
      );
    } else {
      return <Component {...pageProps} />;
    }
  }

  return (
    <>
      <UIContext.Provider value={contextData}>
        <MobileProvider>
          <Layout
            wrapperClassName={`main ${sourceSansPro.className}`}
            preview={isPreviewMode}
          >
            <ComponentWrapper />
          </Layout>
        </MobileProvider>

        {gtmId && gtmEnvironment && gtmAuth && (
          <>
            <GoogleTagManager
              gtmId={`${gtmId}`}
              auth={`${gtmAuth}`}
              preview={`${gtmEnvironment}`}
            />
          </>
        )}

        {!isPreviewMode && <CookiePro />}
      </UIContext.Provider>
    </>
  );
}

OneOceanApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const { locale, asPath } = appContext?.router;

  return {
    ...appProps,
    pageProps: { ...appProps.pageProps, locale, asPath },
  };
};

export default OneOceanApp;
