import styles from './ComponentMediaGallery.module.scss';

import { MediaGallery, DataDigitalAsset } from '@/types';
import { MediaCard } from '@/ui';

const ComponentMediaGallery = ({ galleryAssets }: MediaGallery) => {
  return (
    <div className={styles.mediaGallery}>
      <div className={styles.mediaGallery__container}>
        <div
          className={`${styles.mediaGallery__grid} ${
            galleryAssets.length <= 1 && styles.singleItem
          }`}
        >
          {galleryAssets?.map((item: DataDigitalAsset) => (
            <div key={item?.sys?.id} className={`${styles.mediaGallery__item}`}>
              <MediaCard {...item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ComponentMediaGallery;
