import styles from './ComponentInsights.module.scss';

import React, { useContext, useRef } from 'react';

import {
  Card,
  DynamicComponents,
  Icon,
  UIContext,
  convertMarkdown,
} from '@/ui';
import { IComponentInsights } from '@/types';

import FilterPagination from './FilterPagination/FilterPagination';
import { ComponentInsightsFilter } from './ComponentInsightsFilter';

export const ComponentInsights = ({
  pageData,
  listingItems,
  totalPages,
  currentPage,
  insightTypes,
  pageTags,
  onChangeFilters,
  totalItems,
  additionalData,
  insightsComponents,
  filtersApplied,
  insightPerPage,
  loadStatus,
}: IComponentInsights) => {
  const { exploreText } = useContext(UIContext);

  const insightsRef = useRef<HTMLDivElement | null>(null);

  const handleScrollToInsights = () => {
    if (insightsRef.current) {
      const rect = insightsRef.current.getBoundingClientRect();
      const isInViewport = rect.top >= 0 && rect.bottom <= window.innerHeight;
      if (!isInViewport) {
        insightsRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <div className={styles.componentInsights}>
      <div className={styles.componentInsights__header}>
        <div ref={insightsRef} className={styles.insightsRef} />
        <ComponentInsightsFilter
          pageData={pageData}
          tags={insightTypes}
          topics={pageTags}
          onChangeFilters={onChangeFilters}
        />
      </div>
      <div className={styles.componentInsights__content}>
        {loadStatus !== 'done' && (
          <div className={styles.componentInsights__loading}>
            <Icon
              id='loading'
              className={`${styles.loadingIcon}`}
              width={44}
              height={44}
            />
          </div>
        )}

        {currentPage == '1' && !filtersApplied && (
          <div className={styles.componentInsights__insightsComponentContainer}>
            <DynamicComponents
              components={insightsComponents}
              data={additionalData}
            />
          </div>
        )}
        <div
          className={`${styles.componentInsights__contentInner} ${
            listingItems.length == 0 ? styles.noResults : ''
          }`}
        >
          {listingItems.length > 0 ? (
            listingItems?.map((item: any) => (
              <Card
                key={item.sys.id}
                heading={item.pageHeading}
                image={item.previewMedia}
                link={item.slug}
                tags={item.pageTag}
                cardHeadingVariant={'h3'}
                ctaText={exploreText}
                useTopic={true}
                insightTypeReference={item.insightTypeReference}
                publishDate={item.publishDate}
              >
                {!item.previewMedia &&
                  item.previewText &&
                  convertMarkdown(item.previewText)}
              </Card>
            ))
          ) : (
            <div className={styles.componentInsights__noResults}>
              <span className={styles.componentInsights__zeroResultsText}>
                0 results found.
              </span>
              <span className={styles.componentInsights__searchText}>
                Try searching for something else.
              </span>
            </div>
          )}
        </div>
      </div>
      {listingItems.length > 0 ? (
        <FilterPagination
          currentPage={currentPage}
          totalPages={totalPages}
          totalItems={totalItems}
          listingItems={listingItems}
          onChangeFilters={onChangeFilters}
          insightPerPage={insightPerPage}
          scrollToInsights={handleScrollToInsights}
        />
      ) : null}
    </div>
  );
};

export default ComponentInsights;
