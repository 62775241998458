import React from 'react';

export const HeroClipSVG = () => {
  return (
    <svg
      style={{ position: 'absolute', width: 0, height: 0}}
      width='708'
      height='520'
      viewBox='0 0 708 520'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <clipPath id={'oval-left'} clipPathUnits='objectBoundingBox'>
          <path d='M0,0.5 C0,0.294,0.074,0.112,0.188,0 H0.722 V0 H1 V1 H0.716 H0.188 C0.074,0.888,0,0.706,0,0.5'></path>
        </clipPath>
        <clipPath id={'oval-bottom'} clipPathUnits='objectBoundingBox'>
          <path d='M1,0 H0 V0.734 V0.952 V0.952 H0.001 C0.128,0.982,0.304,1,0.5,1 C0.696,1,0.872,0.982,0.999,0.952 H1 V0.952 V0.734 V0'></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default HeroClipSVG;
