import styles from "./FooterNavigation.module.scss";

import Link from "next/link";

import { NavigationItem, DataNavItem } from "@/types";
import { DataLink } from "@/ui";


const FooterNavigation = ({ navText, navUrl, navChildren }: NavigationItem) => {
    return (
        <div className={styles.footerNavigationList}>
            <h4 className={styles.footerNavigationList__heading}>
                {navUrl ? <DataLink url={navUrl}>{navText}</DataLink> : navText}
            </h4>
            {navChildren && (
                <ul className={styles.footerNavigationList__items}>
                    {navChildren.map(
                        ({ sys, navText, navUrl }: DataNavItem) => (
                            <li
                                key={`footerNav_${sys.id}`}
                                className={styles.footerNavigationList__item}
                            >
                                {navUrl &&
                                    <DataLink
                                        className={styles.footerNavigationList__link}
                                        url={navUrl}
                                    >
                                        {navText}
                                    </DataLink>
                                }
                            </li>
                        )
                    )}
                </ul>
            )}
        </div>
    );
};

export default FooterNavigation;
