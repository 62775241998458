import styles from './CustomerLogin.module.scss';

import { useContext } from 'react';
import Link from 'next/link';

import { useIsMobile } from '@/ui/utils';
import { ComponentCtaButton, UIContext } from '@/ui';

const CustomerLogin = () => {
  const isMobile = useIsMobile();

  const { customerLoginText, customerLoginLink } = useContext(UIContext);

  return isMobile ? (
    <ComponentCtaButton
      ctaType='link'
      ctaText={customerLoginText}
      ctaStyle='outline'
      ctaIcon='arrow'
      ctaIconPosition='right'
      ctaUrl={customerLoginLink}
      cssClass='mobile_button'
    />
  ) : (
    <Link
      href={customerLoginLink}
      className={styles.customerLogin__link}
      target='_blank'
    >
      {customerLoginText}
    </Link>
  );
};
export default CustomerLogin;
