import { ResponsiveImage } from '@/ui';
import styles from './HeroImageClip.module.scss';

const HeroImageClip = ({
  heroMedia,
  ovalBottom = false,
  priority = false,
}: {
  heroMedia?: any;
  ovalBottom?: boolean;
  priority?: boolean;
}) => {
  return (
    <div className={`${styles.heroImageClip__clipped} ${ ovalBottom && styles.mobileBottomOval}`}>
        <ResponsiveImage
          sizes={{ sm: 400, md: 768 }}
          className={styles.heroImageClip__file}
          media={heroMedia}
          priority={priority}
        />
    </div>
  );
};

export default HeroImageClip;
