import React, { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';

import { ComponentCta, HomepageHeroComponent } from '@/types';

import { UIContext, convertMarkdown, useIsMobile } from '@/ui/utils';
import { ComponentCtaButton, ResponsiveImage } from '@/ui';

import styles from './ComponentHomepageHero.module.scss';

const ComponentHomepageHero = ({
  heroHeading,
  heroMedia,
  heroBody,
  ctas,
}: HomepageHeroComponent) => {
  const { playVideoText, pauseVideoText } = useContext(UIContext);

  const [isVideo, setIsVideo] = useState(false);

  const isMobile = useIsMobile();
  const hasMedia = heroMedia?.url;

  const isImage = heroMedia?.contentType.startsWith('image');
  const hasCtas = !!ctas?.length;

  const [isPlaying, setIsPlaying] = useState(true);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const video = !isImage;
    if (video) {
      setIsVideo(video);
    }
  }, [heroMedia, isImage]);

  return (
    <div className={`${styles.homepageHero} ${isVideo ? styles.video : ''}`}>
      <div className={`${styles.homepageHero__container}`}>
        <div className={`${styles.homepageHero__content}`}>
          <div
            className={`${styles.homepageHero__inner} ${
              !hasMedia ? styles.no_media : ''
            }`}
          >
            <h1 className={styles.homepageHero__title}>{heroHeading}</h1>
            <div className={styles.homepageHero__description}>
              {heroBody && convertMarkdown(heroBody)}
            </div>
            {hasCtas && (
              <div className={styles.homepageHero__clickthroughs}>
                {ctas.map((cta: ComponentCta) => {
                  return (
                    <ComponentCtaButton
                      key={cta.ctaText}
                      ctaType='link'
                      ctaText={cta.ctaText}
                      ctaAria={cta.ctaAria}
                      ctaStyle={cta.ctaStyle}
                      ctaIcon={cta.ctaIcon}
                      ctaIconPosition={cta.ctaIconPosition}
                      ctaUrl={cta.ctaUrl}
                      ctaPage={cta.ctaPage}
                      ctaMarketoFormId={cta.ctaMarketoFormId}
                      ctaDigitalAsset={cta.ctaDigitalAsset}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>

        {isImage && hasMedia && (
          <div className={styles.homepageHero__imageWrapper}>
            <ResponsiveImage
              sizes={{ sm: 400, md: 768, lg: 1440, xl: 1920 }}
              className={styles.homepageHero__backgroundMedia}
              media={heroMedia}
              priority
            />
          </div>
        )}
      </div>
      {isVideo && hasMedia && (
        <>
          <ReactPlayer
            className={`${styles.homepageHero__backgroundMedia}`}
            url={heroMedia.url}
            width='100%'
            height='100%'
            playing={isPlaying}
            muted
            loop
            playsinline
            controls={false}
          />
        </>
      )}
      {isVideo && hasMedia && (
        <button
          type='button'
          className={`${styles.homepageHero__videoButton} ${
            isPlaying ? styles.pause : styles.play
          }`}
          onClick={togglePlayPause}
        >
          {!isMobile && (isPlaying ? pauseVideoText : playVideoText)}
        </button>
      )}
    </div>
  );
};

export default ComponentHomepageHero;
