import { insightTypeReferenceFields, whereSysPubExists} from "../fields";

// Fields to push into Algolia index from Contentful
const pagePreviewFields = `
  slug
  pageHeading
  previewText
`;

const dataImageHeadingBodyFields = `
  ihbHeading
  ihbHeadline
  ihbBody
`;

const dataCareerVacancyFields = `
  vacancyHeading
  vacancyBody
  vacancyTag
  vacancyLink
`;


const pageTagCollectionFields = `
  pageTagCollection(
    limit: 2
    ${whereSysPubExists}
  ) {
    items {
      tagName
      tagType
    }
  }
`;

const componentIntroductionFields = `
    textBlockHeading
    textBlockTitle
    textBoxBody
`;

const componentHeroFields = `
  heroHeading
  heroBody
  heroSubheading
`;

const richTextFields = `
  bodyTitle
  bodyContent
`;

const articleSections = `
  articleTitle
  sectionBodyComponentsCollection(
    limit: 6
    ${whereSysPubExists}
  ){
    items {
      __typename
      ... on DataRichText {
        ${richTextFields}
      }
    }
  }
`;

const componentMultiCardCalloutFields = `
    calloutHeading
    insightsMultiCardCallout
    cardsCollection(
      limit:4
      ${whereSysPubExists}
    ) {
        total
        items {
            ${pagePreviewFields}
        }
    }
`;

const componentFeaturedItemsFields = `
  informationListingHeading
  informationListingItemsCollection(
    limit:3
    ${whereSysPubExists}
  ) {
    items {
      ${dataImageHeadingBodyFields}
    }
  }
  relatedInsight {
    ${pagePreviewFields}
  }
`;

const componentCardListingFields = `
  clHeading
  clIntro
  secondaryCtaLink
  cardCtaText
  cardListingCollection(
    limit:3
    ${whereSysPubExists}
  ) {
    items {
      ... on PageStandardPage {${pagePreviewFields}}
      ... on DataCareerVacancy {${dataCareerVacancyFields}}
    }
  }
`;

const componentRichTextBodyFields = `
  richTextTitle
  richTextBodyComponentsCollection(
    limit: 3
    ${whereSysPubExists}
  ) {
    items {
      __typename
      ... on DataRichText {
        ${richTextFields}
      }
    }
  }
`;

const componentFiftyFiftyCalloutFields = `
  calloutHeading
  calloutBody
`;

const componentTestimonialCarouselFields = `
  carouselHeading
  testimonialsCollection(
    limit:3
    ${whereSysPubExists}
  ) {
    items {
      testimonialQuote
      testimonialName
      testimonialNameSub
    }
  }
`;

const componentMarketoBlockFields = `
  marketoHeading
  marketoBody
`;

const componentLatestInsightsCalloutFields = `
  insightsTitle
  insightsHeading
  largeCard(${whereSysPubExists}) {
    publishDate    
    pageHeading
    slug
  }
  smallCardsCollection(
    limit: 4
    ${whereSysPubExists}
  ) {
    items {
      ${insightTypeReferenceFields}      
      publishDate
      pageHeading      
      slug
    }
  }
`;

const componentFeatureTabsFields = `
  tabbedContentCollection(
    limit: 4
    ${whereSysPubExists}
  ) {
    items {
       ${dataImageHeadingBodyFields}
    }
  }
`;

const componentArticleContentFields = `
  articleTitle
  articleSectionsCollection(
    limit: 4    
    ${whereSysPubExists}
  ) {
    items {
      ${articleSections}
    }
  }
  relatedInsights(${whereSysPubExists}) {
    pageTitle
    pageHeading
    slug
    ${insightTypeReferenceFields}    
    previewText
    navigationTag{
      tagTitle
      tagText
    }
  }
`;


export const algoliaQuery = ({ skip, limit }: { skip: number; limit: number }) => `query {
  pageStandardPageCollection(
    locale: "en", 
    skip: ${skip}, 
    limit: ${limit}, 
    ${whereSysPubExists}
  ) {
    items {
      sys {id}
      pageHeading
      pageHeadingFr: pageHeading(locale: "fr")
      description: previewText
      descriptionFr: previewText(locale: "fr")
      publishDate
      slug
      slugFr: slug(locale: "fr")
      metaTitle
      metaTitleFr: metaTitle(locale: "fr")
      metaDescription
      metaDescriptionFr: metaDescription(locale: "fr")
      canonicalUrl
      typeTag: ${insightTypeReferenceFields}            
      tags: ${pageTagCollectionFields}
      previewMedia {
        description
        descripionFr: description(locale: "fr")
      }
      writtenBy {
        employeeName
      }
      componentsCollection(
        limit: 1
      ) {
        items {
          __typename
          ...on ComponentIntroduction {${componentIntroductionFields}}
          ...on ComponentHero {${componentHeroFields}}
          ...on ComponentMultiCardCallout {${componentMultiCardCalloutFields}}
          ...on ComponentFeaturesItems {${componentFeaturedItemsFields}}
          ...on ComponentCardListing {${componentCardListingFields}}
          ...on ComponentRichText {${componentRichTextBodyFields}}
          ...on ComponentFiftyFiftyCallout {${componentFiftyFiftyCalloutFields}}
          ...on ComponentTestimonialCarousel {${componentTestimonialCarouselFields}}
          ...on ComponentMarketoBlock {${componentMarketoBlockFields}}
          ...on ComponentLatestInsightsCallout {${componentLatestInsightsCalloutFields}}
          ...on ComponentTabbedContent {${componentFeatureTabsFields}}
          ...on ComponentArticleContent{${componentArticleContentFields}}
        }
      }
      componentsFrCollection: componentsCollection(locale: "fr", limit: 1) {
        items {
          __typename
          ...on ComponentIntroduction {${componentIntroductionFields}}
          ...on ComponentHero {${componentHeroFields}}
          ...on ComponentMultiCardCallout {${componentMultiCardCalloutFields}}
          ...on ComponentFeaturesItems {${componentFeaturedItemsFields}}
          ...on ComponentCardListing {${componentCardListingFields}}
          ...on ComponentRichText {${componentRichTextBodyFields}}
          ...on ComponentFiftyFiftyCallout {${componentFiftyFiftyCalloutFields}}
          ...on ComponentTestimonialCarousel {${componentTestimonialCarouselFields}}
          ...on ComponentMarketoBlock {${componentMarketoBlockFields}}
          ...on ComponentLatestInsightsCallout {${componentLatestInsightsCalloutFields}}
          ...on ComponentTabbedContent {${componentFeatureTabsFields}}
          ...on ComponentArticleContent{${componentArticleContentFields}}
        }
      }
    }
  }
}`;