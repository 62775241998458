import { Icon } from '@/ui/base';
import styles from './SearchPagination.module.scss';
import classnames from 'classnames';
import { usePagination, useStats } from 'react-instantsearch-hooks-web';

interface PaginationProps {
  [key: string]: any;
}

export const SearchPagination = (props: PaginationProps) => {
  const { showPrevious, showNext, nbHits, nbHitsTotal } = props;
  const paginationProps = usePagination();
  const { hitsPerPage } = useStats();
  const { currentRefinement, nbPages, refine, createURL } = paginationProps;
  if (nbPages < 2) {
    return null;
  }

  const pageStart = currentRefinement * (hitsPerPage ?? 1) + 1;
  const pageEnd = nbHits + currentRefinement * (hitsPerPage ?? 1);

  return (
    <>
      <ul className={styles.pagination}>
        <div className={styles.pagination__summary}>
          {`Showing ${pageStart} - ${pageEnd} of ${nbHitsTotal} results`}
        </div>
        {showPrevious && (
          <button
            type='button'
            className={`${styles.pagination__item} ${
              currentRefinement <= 0 ? styles['disabled'] : ''
            }`}
            style={{
              transform: 'scale(-1)',
            }}
            disabled={currentRefinement <= 0}
            onClick={(event) => {
              event.preventDefault();
              refine(currentRefinement - 1);
            }}
          >
            <Icon id='chevron-arrow' width={11} height={11} />
          </button>
        )}
        {new Array(nbPages).fill(null).map((_, index) => {
          const page = index + 1;
          return (
            <li key={`search-pagination-${page}`}>
              <a
                className={classnames(styles.pagination__item, {
                  [styles.pagination__item__active]:
                    currentRefinement + 1 === page,
                })}
                href={createURL(page)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(index);
                }}
              >
                {page}
              </a>
            </li>
          );
        })}
        {showNext && (
          <button
            type='button'
            className={`${styles.pagination__item} ${
              currentRefinement + 1 >= nbPages ? styles['disabled'] : ''
            }`}
            disabled={currentRefinement + 1 >= nbPages}
            onClick={(event) => {
              event.preventDefault();
              refine(currentRefinement + 1);
            }}
          >
            <Icon id='chevron-arrow' width={11} height={11} />
          </button>
        )}
      </ul>
    </>
  );
};
