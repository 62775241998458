import styles from './FilterPagination.module.scss';

import ReactPaginate from 'react-paginate';

import { useIsMobile } from '@/ui/utils';

export const FilterPagination = ({
  totalPages,
  totalItems,
  currentPage,
  onChangeFilters,
  scrollToInsights,
  listingItems,
  insightPerPage,
}: {
  totalItems: number;
  totalPages: string;
  currentPage: string;
  onChangeFilters: Function;
  scrollToInsights: Function;
  listingItems: any[];
  insightPerPage: string;
}) => {
  const handlePageChange = (selected: { selected: number }) => {
    const newPage = selected.selected + 1;
    onChangeFilters(null, null, newPage.toString());
    scrollToInsights();
  };

  const perPage = parseInt(insightPerPage, 10);

  let showing = '';

  if (!listingItems) {
    return null;
  } else {
    const currentPageStart = (parseInt(currentPage, 10) - 1) * perPage + 1;
    const currentPageEnd = Math.min(currentPageStart + perPage - 1, totalItems);

    if (currentPage === '1' || currentPageStart === currentPageEnd) {
      showing = `Showing ${currentPageEnd} of ${totalItems}`;
    } else {
      showing = `Showing ${currentPageStart} - ${currentPageEnd} of ${totalItems}`;
    }
  }

  const isMobile = useIsMobile();

  const showPaginationPages = isMobile ? 2 : 3;

  if (parseInt(totalPages, 10) === 1) {
    return (
      <div className={styles.filterPagination}>
        <div
          className={`${styles.filterPagination__results} ${styles.no_pagination}`}
        >
          {showing}
        </div>
      </div>
    );
  }

  const forcePage = Math.min(
    parseInt(currentPage, 10) - 1,
    parseInt(totalPages, 10) - 1
  );

  return (
    <div className={styles.filterPagination}>
      <div className={styles.filterPagination__results}>{showing}</div>
      <div className={styles.filterPagination__paginationContainer}>
        <ReactPaginate
          previousLabel='<'
          nextLabel='>'
          breakLabel='...'
          breakClassName={styles.paginationEllipsis}
          pageCount={parseInt(totalPages, 10)}
          forcePage={forcePage}
          marginPagesDisplayed={1}
          pageRangeDisplayed={showPaginationPages}
          onPageChange={handlePageChange}
          containerClassName={styles.reactPagination}
          pageClassName={styles.paginationItemNumber}
          activeClassName={styles.paginationActive}
          previousClassName={styles.paginationItemPrev}
          nextClassName={styles.paginationItemNext}
          disabledClassName={styles.paginationDisabled}
        />
      </div>
    </div>
  );
};

export default FilterPagination;
