import type { BaseQueryProps } from '@/types';

import {
  standardPageFields,
  standardPageComponentTypeNames,
  standardPageComponentCount,
} from '@/intergrations';

export interface GetPageQueryProps extends BaseQueryProps {
  slug: string;
}

export const pageQuery = ({
  slug,
  locale,
  preview = false,
  limit = 1,
}: GetPageQueryProps) => {
  const slugFilter = slug ? `where: {slug: "${slug}"}` : '';
  let previewTrue = preview ? 'preview: true' : '';
  return `query {
    pageStandardPageCollection(
      ${slugFilter}
      limit: ${limit}
      locale: "${locale}"
      ${previewTrue} 
      ) {
      items {
        ${standardPageFields}
        ${standardPageComponentTypeNames}
        ${standardPageComponentCount}
      }
    }
  }`;
};
