import { collection } from '../builder';

import {
  insightTypeReferenceFields,
  pageTagCollectionFields,
  siteConfigurationFields,
  standardPageFields
} from '@/intergrations';

export const baseQuery = ({ locale, preview = false }: { locale: string, preview: boolean }) => `{
  ${collection('configurationSite', siteConfigurationFields, { limit: 1, locale: `"${locale}"`, preview })}
}`;

export const allStandardPages = ({ locale, limit, preview, slugFilter, filters, insightTypes }:
  { locale?: string, limit: number, preview: boolean, slugFilter: string, filters: any, insightTypes?: string[] }) => {

  if (filters.insightTypes?.length > 0) {
    insightTypes = insightTypes?.filter((ct) => filters.insightTypes.includes(ct))
  }

  let pageTags: string[] = [];

  if (filters.pageTags.length > 0) {
    pageTags = filters.pageTags.filter((tt: string) => {
      return tt !== '';
    });
  }
  const requestedPage = parseInt(filters.page, 10);

  let skip = 0;

  if (requestedPage >= 2) {
    skip = (requestedPage - 1) * limit;
  }

  let previewTrue = preview ? 'preview: true' : '';

  // This is the pageTags filter request
  const pageTagFilter = pageTags.length !== 0 ? `
    pageTagCollection_exists: true
    pageTag:{
      tagName_in: ["${pageTags.join('","')}"]
    }
  ` : '';

  const insightsReference = insightTypes?.length !== 0 ? `
  insightTypeReference:{
    ${previewTrue}
    pluralName_in: ["${insightTypes?.join('","')}"${slugFilter}]
  }` : '';

  return `
  pageStandardPageCollection(
    limit: ${limit}
    locale: "${locale}"
    ${previewTrue}
    skip: ${skip}
    order: publishDate_DESC
    where: {
      ${insightsReference}
      ${pageTagFilter}
      slug_exists: true
      insightTypeReference_exists: true
      publishDate_exists: true, 
    }
  ) {
    total
    items {
      ${standardPageFields}
    }
  }
  allStandardPages: pageStandardPageCollection(
    locale: "${locale}"
    ${previewTrue}       
    where: {
      ${insightsReference}
      ${pageTagFilter}
      slug_exists: true
      insightTypeReference_exists: true
    } 
  ) {
    total    
  }
  insightTypes: pageStandardPageCollection(    
    locale: "${locale}"
    order: slug_ASC
    where: {slug_exists: true}
  ) {
    items {      
      ${insightTypeReferenceFields}      
    }
  }
  pageTags: pageStandardPageCollection(
    locale: "${locale}"    
    ${previewTrue}
    where: {
      ${insightsReference}
      ${pageTagFilter}
      slug_exists: true
    }
  ) {
    items {
      ${pageTagCollectionFields}      
    }
  }
  `
}

export const newsletterQuery = ({ entryId, locale, preview }:
  { entryId: string, locale?: string, preview: boolean }) => {

  const previewTrue = preview ? 'preview: true' : '';
  const localeTrue = locale ? `locale: "${locale}"` : 'locale: "en"';
  const setEntryId = entryId ? `where: {sys: {id: "${entryId}"}}` : '';

  return `{
    entryCollection(          
      ${setEntryId}
      limit: 1
      ${localeTrue}
      ${previewTrue}
    ) {
      items {
        ... on ComponentMarketoBlock {
          sys { id }
          marketoType
          marketoHeading
          marketoBody
          marketoCtaText
          marketoEmailText
          marketoEmailExampleText
          marketoTopics
          marketoTopicPromptText
          marketoTopicText
          marketoFormId
          marketoSuccessMessage
        }
      }
    }
  }
  `
}