import 'keen-slider/keen-slider.min.css';
import styles from './Carousel.module.scss';

import { ReactNode, useState } from 'react';
import { useKeenSlider, KeenSliderPlugin } from 'keen-slider/react';

import { Icon } from '@/ui/base';

interface CarouselProps {
  id: string;
  className?: string;
  loadedClass?: string;
  accessibleTitle: string;
  slideCountLabel?: string;
  slideCurrentToken?: string;
  slideTotalToken?: string;
  slideLabel?: string;
  previousLabel?: string;
  nextLabel?: string;
  autoplay?: boolean;
  children: ReactNode;
  slideDelay?: number;
  controlsOverlay?: boolean;
}

const adaptiveHeight: KeenSliderPlugin = (slider) => {
  function updateHeight() {
    slider.container.style.height =
    `${slider.slides[slider.track.details.rel]?.firstElementChild?.clientHeight}px`
  }

  slider.on("created", () => {
    updateHeight()

    slider.container.classList.add(styles.init);
  });
  slider.on("slideChanged", updateHeight)
  slider.on("updated", updateHeight)
}

const autoplayCarousel= (delay:number): KeenSliderPlugin => (slider) => {
  let timeout: ReturnType<typeof setTimeout>
  let mouseOver = false

  function clearNextTimeout() {
    clearTimeout(timeout)
  }

  function nextTimeout() {
    clearTimeout(timeout)

    if (mouseOver) {
      return
    }

    timeout = setTimeout(() => {
      slider.next()
    }, delay)
  }

  slider.container.addEventListener("mouseover", () => {
    mouseOver = true
    clearNextTimeout()
  })

  slider.container.addEventListener("mouseout", () => {
    mouseOver = false
    nextTimeout()
  })

  nextTimeout()

  slider.on("dragStarted", clearNextTimeout)
  slider.on("animationEnded", nextTimeout)
  slider.on("updated", nextTimeout)
}

const keyboardEvents: KeenSliderPlugin = (slider) => {
  slider.container.addEventListener("keydown", (event) => {
    const key = event.key;

    switch (key) {
      case "ArrowLeft":
        slider.prev()
        break;
      case "ArrowRight":
        slider.next()
        break;
    }
  });
}

const Carousel = ({
  id,
  className = '',
  loadedClass = '',
  accessibleTitle,
  slideCountLabel = 'item {CURRENT} of {TOTAL}',
  slideCurrentToken = '{CURRENT}',
  slideTotalToken = '{TOTAL}',
  slideLabel = 'slide',
  previousLabel = 'previous',
  nextLabel = 'next',
  autoplay = false,
  children,
  slideDelay = 5000,
  controlsOverlay = false,
}: CarouselProps) => {

  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [totalSlides, setTotalSlides] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    loop: true,
    
    slideChanged(slider) {
      const current = slider.track.details.rel;
      setCurrentSlide(current);

      slider.slides.forEach((slide, index) => {
        if (index === current) {
          slide.removeAttribute('aria-hidden');
          slide.removeAttribute('tabindex');
          slide.style.visibility = "visible";
        } else {
          slide.setAttribute('aria-hidden', 'true');
          slide.setAttribute('tabindex', '-1');
          slide.style.visibility = "hidden";
        }
      });
    },
    created(slider) {
      setTotalSlides(slider.track.details.slides.length);

      slider.slides.forEach((slide, index) => {
        if (index > 0) {
          slide.setAttribute('aria-hidden', 'true');
          slide.setAttribute('tabindex', '-1');
          slide.style.visibility = "hidden";
        }
      });

      if (autoplay && slider.slides.length > 1) {
        autoplayCarousel(slideDelay)(slider);
      }

      setLoaded(true);
    },
  }, [adaptiveHeight, keyboardEvents]);

  const slideCount = () =>
    slideCountLabel
      .replace(slideCurrentToken, Number(currentSlide + 1).toString())
      .replace(slideTotalToken, totalSlides.toString());


  const isCarousel = totalSlides > 1 && loaded && instanceRef.current; 

  const isOverlay = controlsOverlay && isCarousel;

  const controlsBelow = isCarousel && !isOverlay;



  return (
    <section
      className={`${className} ${loaded ? loadedClass : ''} ${styles.carousel__overlay}`}
      aria-label={accessibleTitle}
      aria-roledescription='carousel'
      id={id}
    >
      {isCarousel && (
        <div className={styles.carousel__liveregion} aria-live='polite'>
          {slideCount()}
        </div>
      )}
      <div className={`keen-slider ${styles.carousel__slider}`} ref={sliderRef} >
        {children}
      </div>
      {controlsBelow && (
        <>
          <div className={`${styles.carousel__controls} carousel__controls`}>
            <ul className={`${styles.carousel__controlList} carousel__dots`}>
              {[
                ...Array(
                  instanceRef.current?.track.details.slides.length
                ).keys(),
              ].map((idx) => {
                const isCurrent = currentSlide === idx;
                return (
                  <li key={idx} aria-current={isCurrent}>
                    <button
                      type='button'
                      className={`${styles.carousel__dot} ${
                        isCurrent ? styles.active : ''
                      }`}
                      onClick={() => instanceRef.current?.moveToIdx(idx)}
                      aria-label={slideLabel.replace(
                        slideCurrentToken,
                        `${idx + 1}`
                      )}
                      aria-controls={id}
                    />
                  </li>
                );
              })}
            </ul>
            <ul className={`${styles.carousel__controlList} carousel__arrows`}>
              <li>
                <button
                  type='button'
                  onClick={() => instanceRef?.current?.prev()}
                  className={`${styles.carousel__button} ${styles.prev}`}
                  aria-controls={id}
                >
                  <Icon id='chevron-arrow' width={9} height={16} />
                  <span className={styles.carousel__buttonText}>
                    {previousLabel}
                  </span>
                </button>
              </li>
              <li>
                <button
                  type='button'
                  onClick={() => instanceRef?.current?.next()}
                  className={styles.carousel__button}
                  aria-controls={id}
                >
                  <Icon id='chevron-arrow' width={9} height={16} />
                  <span className={styles.carousel__buttonText}>
                    {nextLabel}
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </>
      )}
      {isOverlay && (
        <>          
          <ul className={`${styles.carousel__controlList} ${styles.carousel__overlay_dots}`}>
            {[
              ...Array(
                instanceRef.current?.track.details.slides.length
              ).keys(),
            ].map((idx) => {
              const isCurrent = currentSlide === idx;
              return (
                <li key={idx} aria-current={isCurrent}>
                  <button
                    type='button'
                    className={`${styles.carousel__dot} ${
                      isCurrent ? styles.active : ''
                    }`}
                    onClick={() => instanceRef.current?.moveToIdx(idx)}
                    aria-label={slideLabel.replace(
                      slideCurrentToken,
                      `${idx + 1}`
                    )}
                    aria-controls={id}
                  />
                </li>
              );
            })}
          </ul>
          <ul className={`${styles.carousel__controlList} ${styles.carousel__overlay_arrows}`}>
            <li>
              <button
                type='button'
                onClick={() => instanceRef?.current?.prev()}
                className={`${styles.carousel__button} ${styles.prev}`}
                aria-controls={id}
              >
                <Icon id='chevron-arrow' width={9} height={16} />
                <span className={styles.carousel__buttonText}>
                  {previousLabel}
                </span>
              </button>
            </li>
            <li>
              <button
                type='button'
                onClick={() => instanceRef?.current?.next()}
                className={styles.carousel__button}
                aria-controls={id}
              >
                <Icon id='chevron-arrow' width={9} height={16} />
                <span className={styles.carousel__buttonText}>
                  {nextLabel}
                </span>
              </button>
            </li>
          </ul>          
        </>
      )}

      {}
    </section>
  );
};

export default Carousel;
