import { BaseQueryProps } from "@/types";
import { allStandardPages } from "./base";
import {
  searchPageComponentCollectionFields,
} from '@/intergrations';


export interface GetSearchPageQueryProps extends BaseQueryProps {
  slug?: string;
  filters?: {
    locale: string;
    page: string;
    insightTypes: string[];
    pageTags: string[];
  };
}

export const searchQuery = ({
  slug,
  locale,
  preview = false,
  limit = 1,
  filters = {
    locale: 'en',
    page: '1',
    pageTags: [],
    insightTypes: [],
  },
}: GetSearchPageQueryProps) => {
  const slugFilter = slug ? `where: {slug: "${slug}"}` : '';

  let previewTrue = preview ? 'preview: true' : '';

  return `query {
    pageSearchCollection(
        ${slugFilter}
        limit: ${limit}
        locale: "${locale}"
        ${previewTrue}
        ) {
        items {
          __typename
          sys {
            id
          }
          pageTitle
          metaTitle
          metaDescription
          viewCtaText
          noResultsText
          pageHeading
          resultsForText
          searchAgainText
          popularPagesText
          filterText
          filterByText
          filteredByText
          filterResultsText
          topicsText
          contentTypeText
          noFiltersAvailableText
          allResultsText
          applyFiltersText
          clearAllFiltersText
          popularPagesCollection(limit: 6) {
            items {
              pageHeading
              slug
            }
          }
          ${searchPageComponentCollectionFields}
        }
      }
      ${allStandardPages({ locale, limit, preview, slugFilter, filters })}
    }
  `;
};