import styles from './ComponentInsightsFilter.module.scss';

import React, { useContext, useEffect, useState } from 'react';

import { useIsMobile } from '@/ui/utils';
import { DataTag } from '@/types';
import { ComponentCtaButton, UIContext } from '@/ui';

import { FilterTagPill } from './FilterTagPill/FilterTagPill';
import { FilterCheckbox } from './FilterCheckbox/FilterCheckbox';
import { FilterRadioButton } from './FilterRadioButton/FilterRadioButton';

export const ComponentInsightsFilter = ({
  pageData,
  tags,
  topics,
  onChangeFilters,
  formatted = false,
}: {
  pageData: any;
  tags?: string[];
  topics: string[];
  onChangeFilters: Function;
  formatted?: boolean;
}) => {
  const { closeText } = useContext(UIContext);

  const isMobile = useIsMobile();

  const [showFilterOptions, setShowFilterOptions] = useState(false);

  const [activeFilter, setActiveFilter] = useState('All');
  const [showMobileFilterOptions, setShowMobileFilterOptions] = useState(false);

  const [showMobileContentList, setShowMobileContentList] = useState(false);
  const [showMobileTopicsList, setShowMobileTopicsList] = useState(false);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState<
    { id: string; text: string }[]
  >([]);

  const [appliedFilters, setAppliedFilters] = useState<
    { id: string; text: string }[]
  >([]);

  const [selectedCount, setSelectedCount] = useState<number>(
    selectedCheckboxes.length
  );

  const [prevSelectedCheckboxes, setPrevSelectedCheckboxes] = useState<
    { id: string; text: string }[]
  >([]);

  const handleApplyDesktopFiltersClick = () => {
    setShowFilterOptions(false);
    const currentCheckboxes = selectedCheckboxes;
    setAppliedFilters(currentCheckboxes);
    onChangeFilters(null, currentCheckboxes, 1);
  };

  const handleMobileFilterClick = () => {
    setPrevSelectedCheckboxes(selectedCheckboxes);
    setShowMobileFilterOptions((prevState) => !prevState);
  };

  const handleMobileApplyFiltersClick = () => {
    setShowFilterOptions(false);
    const currentCheckboxes = selectedCheckboxes;
    setAppliedFilters(currentCheckboxes);
    onChangeFilters(null, currentCheckboxes, 1);
    setShowMobileFilterOptions(false);
  };

  const handleCancelMobileFilters = () => {
    setShowMobileFilterOptions(false);
    setSelectedCheckboxes(prevSelectedCheckboxes);
  };

  useEffect(() => {
    if (showMobileFilterOptions) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [showMobileFilterOptions]);

  return (
    <div className={styles.insightsFilters}>
      <div className={styles.insightsFilters__inner}>
        <div className={styles.insightsFilters__innerTop}>
          <div className={styles.insightsFilters__topFilters}>
            {isMobile && (
              <div className={styles.insightsFilters__filterTop}>
                <div className={styles.insightsFilters__filterHeading}>
                  {pageData?.filterInsights}
                </div>
                <button
                  type='button'
                  tabIndex={0}
                  className={`${styles.insightsFilters__filterButton} ${
                    showFilterOptions ? styles['active'] : ''
                  }`}
                  onClick={handleMobileFilterClick}
                >
                  {pageData?.filterText}
                  {selectedCheckboxes.length > 0 && (
                    <span className={styles.insightsFilters__topicCount}>
                      {selectedCheckboxes.length}
                    </span>
                  )}
                </button>
              </div>
            )}

            {!isMobile && (
              <React.Fragment>
                <div className={styles.insightsFilters__filter}>
                  <div className={styles.insightsFilters__filterTags}>
                    <FilterTagPill
                      key={'filterTag_allresults'}
                      tagText={pageData?.allResultsText}
                      id={`allresults`}
                      onClick={() => {
                        setActiveFilter('All');
                        onChangeFilters('All', null, 1);
                      }}
                      active={activeFilter === 'All'}
                    />
                    {tags?.map((filter: string) => (
                      <FilterTagPill
                        key={'filterTag_' + filter}
                        tagText={filter}
                        id={filter}
                        onClick={() => {
                          setActiveFilter(filter);
                          onChangeFilters(filter, null, 1);
                        }}
                        active={activeFilter === filter}
                      />
                    ))}
                  </div>
                </div>

                {tags?.length !== 0 && (
                  <div className={styles.insightsFilters__topics}>
                    <div className={styles.insightsFilters__topicHeading}>
                      {pageData?.filterByText}
                    </div>

                    <div className={styles.insightsFilters__topicsList}>
                      <button
                        type='button'
                        tabIndex={0}
                        className={`${styles.insightsFilters__topicButton} ${
                          showFilterOptions ? styles['active'] : ''
                        }`}
                        onClick={() => {
                          setShowFilterOptions((prevState) => !prevState);
                        }}
                      >
                        {pageData?.topicsText}
                        {selectedCheckboxes.length > 0 && (
                          <span className={styles.insightsFilters__topicCount}>
                            {selectedCheckboxes.length}
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>

          {showMobileFilterOptions && isMobile && (
            <div className={styles.insightsFilters__mobileFilters}>
              <div className={styles.insightsFilters__mobileFilterInner}>
                <div className={styles.insightsFilters__mobileFiltersTop}>
                  <ComponentCtaButton
                    key={'mobileFilterOptionsButton'}
                    ctaType='button'
                    ctaText={closeText}
                    ctaStyle='outline'
                    ctaIcon='close'
                    ctaIconPosition='left'
                    cssClass='mobile-back'
                    onClick={handleCancelMobileFilters}
                  />
                </div>
                <div
                  className={`${styles.insightsFilters__mobileFiltersBottom}`}
                >
                  <div className={styles.insightsFilters__mobilefilterApply}>
                    <ComponentCtaButton
                      key={'mobileFilterApplyButton'}
                      ctaStyle='solid'
                      ctaText={pageData?.applyFiltersText}
                      onClick={handleMobileApplyFiltersClick}
                      ctaType={'button'}
                    />
                  </div>

                  <div className={styles.insightsFilters__mobileFilterBy}>
                    {pageData?.filterByText}
                  </div>

                  <div
                    className={
                      styles.insightsFilters__mobileFiltersContentLists
                    }
                  >
                    <div
                      className={`${
                        styles.insightsFilters__mobileFiltersContentTypes
                      } ${showMobileContentList ? styles['active'] : ''}`}
                    >
                      <div
                        className={styles.insightsFilters__listHeading}
                        onClick={() =>
                          setShowMobileContentList((prevState) => !prevState)
                        }
                      >
                        {pageData?.contentTypeText}
                      </div>
                      <div
                        className={
                          styles.insightsFilters__mobileFiltersContentTypesList
                        }
                      >
                        <div
                          className={styles.insightsFilters__mobileListInner}
                        >
                          <div className={styles.insightsFilters__filterTags}>
                            <FilterRadioButton
                              key={'filterRadio_allresults'}
                              id={'allresults'}
                              text={pageData?.allResultsText}
                              name={'insightTypeFilter'}
                              onChangeFilters={(
                                filter: string,
                                topics: string | string[]
                              ) => {
                                setActiveFilter('All');
                                onChangeFilters(filter, topics, 1);
                              }}
                              active={activeFilter === 'All'}
                            />
                            {tags?.map((filter: string) => (
                              <FilterRadioButton
                                key={'filterRadio_' + filter}
                                id={filter}
                                text={filter}
                                name={'insightTypeFilter'}
                                onChangeFilters={(
                                  filter: string,
                                  topics: any
                                ) => {
                                  setActiveFilter(filter);
                                  onChangeFilters(filter, topics, 1);
                                }}
                                active={activeFilter === filter}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${
                        styles.insightsFilters__mobileFiltersTopics
                      } ${showMobileTopicsList ? styles['active'] : ''}`}
                    >
                      <div
                        className={styles.insightsFilters__listHeading}
                        onClick={() =>
                          setShowMobileTopicsList((prevState) => !prevState)
                        }
                      >
                        {pageData?.topicsText}
                        {selectedCheckboxes.length > 0 && (
                          <span className={styles.insightsFilters__topicCount}>
                            {selectedCheckboxes.length}
                          </span>
                        )}
                      </div>

                      <div
                        className={
                          styles.insightsFilters__mobileFiltersTopicsList
                        }
                      >
                        <div
                          className={styles.insightsFilters__mobileListInner}
                        >
                          {topics.length > 0 ? (
                            topics?.map((topic: any) => {
                              return (
                                <FilterCheckbox
                                  key={'desktopfilterCheckbox_' + topic.tagName}
                                  id={`${topic.tagName}`}
                                  text={`${topic.tagName}`}
                                  count={selectedCount}
                                  setCount={setSelectedCount}
                                  selectedCheckboxes={selectedCheckboxes}
                                  setSelectedCheckboxes={setSelectedCheckboxes}
                                />
                              );
                            })
                          ) : (
                            <div className={styles.no_filters}>
                              {pageData?.noFiltersAvailableText}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {showFilterOptions && !isMobile && (
            <div className={styles.insightsFilters__topTopics}>
              <div
                className={`${styles.insightsFilters__filterBoxes} ${
                  topics.length === 0 ? styles.no_filters : ''
                }`}
              >
                {topics.length > 0 ? (
                  topics?.map((topic: any) => {
                    return (
                      <FilterCheckbox
                        key={'mobilefilterCheckbox_' + topic.tagName}
                        id={`${topic.tagName}`}
                        text={`${topic.tagName}`}
                        count={selectedCount}
                        setCount={setSelectedCount}
                        selectedCheckboxes={selectedCheckboxes}
                        setSelectedCheckboxes={setSelectedCheckboxes}
                      />
                    );
                  })
                ) : (
                  <div className={styles.no_filters}>
                    {pageData?.noFiltersAvailableText}
                  </div>
                )}
              </div>
              <div className={styles.insightsFilters__filterApply}>
                <ComponentCtaButton
                  ctaStyle='solid'
                  ctaText={pageData?.applyFiltersText}
                  onClick={handleApplyDesktopFiltersClick}
                  ctaType={'button'}
                />
              </div>
            </div>
          )}
        </div>

        {appliedFilters.length > 0 && (
          <div className={styles.insightsFilters__innerBottom}>
            <div className={styles.insightsFilters__appliedFiltersWrapper}>
              <div className={styles.insightsFilters__appliedFiltersHeading}>
                {pageData?.filteredByText}
              </div>

              <div className={styles.insightsFilters__appliedFilters}>
                {appliedFilters.map((filter, index: number) => (
                  <ComponentCtaButton
                    key={'appliedFilters_' + filter.id}
                    ctaStyle='solid'
                    ctaText={filter.text}
                    ctaSize='small'
                    ctaIcon='close'
                    ctaIconPosition='left'
                    onClick={() => {
                      const newAppliedFilters = [...appliedFilters];
                      newAppliedFilters.splice(index, 1);
                      setAppliedFilters(newAppliedFilters);
                      onChangeFilters(null, newAppliedFilters, 1);

                      const newSelectedCheckboxes = selectedCheckboxes.filter(
                        (checkbox) => checkbox.id !== filter.id
                      );
                      setSelectedCheckboxes(newSelectedCheckboxes);
                    }}
                    ctaType={'button'}
                    cssClass='applied_filter'
                  />
                ))}
                <ComponentCtaButton
                  ctaStyle='solid'
                  ctaText={pageData?.clearAllFiltersText}
                  ctaSize='small'
                  ctaIcon='close'
                  ctaIconPosition='right'
                  onClick={() => {
                    setSelectedCheckboxes([]);
                    setAppliedFilters([]);
                    onChangeFilters('All', [], 1);
                    setActiveFilter('All');
                    setSelectedCount(0);
                  }}
                  ctaType={'button'}
                  cssClass='filter_clear'
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ComponentInsightsFilter;
