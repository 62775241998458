import 'keen-slider/keen-slider.min.css';

import React from 'react';

const CarouselSlide = ({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={`keen-slider__slide ${className ?? ''}`}
      {...props}
      role='group'
      aria-roledescription='slide'
    >
      {children}
    </div>
  );
};

export default CarouselSlide;
