import { UIContext, convertMarkdown } from '@/ui/utils';
import { useContext } from 'react';
import styles from './SearchItem.module.scss';
import { format } from 'date-fns';
import { ComponentCtaButton } from '../../ComponentCtaButton';

interface SearchItemProps {
  [key: string]: any;
}

export const SearchItem = (
  props: Omit<SearchItemProps, '_highlightResult'>
) => {
  const { locale } = useContext(UIContext);
  const suffixByLocale =
    locale === 'en' ? '' : locale.charAt(0).toUpperCase() + locale.slice(1);

    const getMultiLocalePropertyName = (key: string, subkey?: string) => {
    let value = props[key + suffixByLocale] ?? props[key];
    // This function has now been updated to support subkey properties for object return values
    if (subkey && value) {
      return value[subkey];
    }
    return value;
  };

  return (
    <div className={styles.searchItem}>
      <div className={styles.searchItem__tags}>
        {getMultiLocalePropertyName('typeTag', 'insightName')}
        {props.pageTags?.length > 0 && (
          <div className={styles.searchItem__tags__topic}>
            {props.pageTags.map((item: string, index: number) => {
              return (
                <span
                  key={`topic_tag_${item}_${props.pageHeading}_${props.publishDate}}`}
                  className={styles.searchItem__tags__topicItem}
                >
                  {item}
                </span>
              );
            })}
          </div>
        )}
      </div>
      <div className={styles.searchItem__second}>
        <div className={styles.searchItem__second__heading}>
          {getMultiLocalePropertyName('pageHeading')}
        </div>
        {props.publishDate && (
          <div className={styles.searchItem__second__date}>
            {format(new Date(props.publishDate), 'MMMM d, yyyy')}
          </div>
        )}
        <div>
          {convertMarkdown(getMultiLocalePropertyName('description') ?? '')}
        </div>
      </div>
      <div className={styles.searchItem__last}>
        <div className={styles.searchItem__last__cta}>
          <ComponentCtaButton
            ctaType='button'
            ctaText={props.viewCtaText}
            ctaStyle='underline'
            ctaIcon='arrow'
            ctaIconPosition='right'
            ctaUrl={''}
          />
        </div>
      </div>
    </div>
  );
};
