import React from 'react';
import styles from './FilterCheckbox.module.scss';

interface CheckboxProps {
  id: string;
  text: string;
  count: number;
  setCount: (count: number) => void;
  selectedCheckboxes: { id: string; text: string }[];
  setSelectedCheckboxes: (
    selectedCheckboxes: { id: string; text: string }[]
  ) => void;
}

export function FilterCheckbox({
  id,
  text,
  count,
  setCount,
  selectedCheckboxes,
  setSelectedCheckboxes,
}: CheckboxProps) {
  const isChecked = selectedCheckboxes.some((checkbox) => checkbox.id === id);

  const handleCheckboxChange = () => {
    if (isChecked) {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((checkbox) => checkbox.id !== id)
      );
      setCount(count - 1);
    } else {
      setSelectedCheckboxes([...selectedCheckboxes, { id, text }]);
      setCount(count + 1);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleCheckboxChange();
    } else if (event.key === ' ') {
      handleCheckboxChange();
      event.preventDefault();
    }
  };

  return (
    <div className={styles.checkboxContainer}>
      <input
        tabIndex={0}
        type='checkbox'
        checked={isChecked}
        onChange={handleCheckboxChange}
        onKeyDown={handleKeyPress}
        id={id}
        aria-label={`checkbox for ${text}`}
        className={styles.checkboxInput}
      />
      <label
        htmlFor={id}
        className={`${styles.checkboxLabel}  ${
          isChecked ? styles['active'] : ''
        }`}
      >
        {text}
      </label>
    </div>
  );
}

export default FilterCheckbox;
