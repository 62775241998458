import styles from './Breadcrumb.module.scss';

import { useRouter } from 'next/router';
import Link from 'next/link';

import { useFullPath } from '@/ui/hooks';
import { IBreadcrumb } from '@/types';
import { capitalize } from '@/ui/helpers/captalise';

const BreadcrumbItem = ({ pageTitle, path }: IBreadcrumb) => {
  
  const linkContent = pageTitle ?? capitalize(path);

  return (
    <li key={path} className={styles.breadcrumb__item}>
      <Link className={styles.breadcrumb__link} href={path}>
        {linkContent}
      </Link>
    </li>
  );
};

const Breadcrumb = ({
  usedOnLightBackground = false,
}: {
  usedOnLightBackground?: boolean;
}) => {
  const { asPath, locale } = useRouter();
  const paths = asPath.split('/');

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pathsWithPageTitle = paths
    .slice(1)
    .map((path) => useFullPath(path.split('?')[0], true))
    .filter((path) => path !== '') as IBreadcrumb[];

  if (pathsWithPageTitle?.length < 1) {
    return null;
  }

  const breadcrumb =
    pathsWithPageTitle.length > 5
      ? [...pathsWithPageTitle.slice(0, 1)]
      : pathsWithPageTitle;
  const breadcrumbCurrent =
    pathsWithPageTitle.length > 5
      ? [
          ...pathsWithPageTitle.slice(
            pathsWithPageTitle.length - 2,
            pathsWithPageTitle.length
          ),
        ]
      : [];

  return (
    <div
      className={`${styles.breadcrumb} ${usedOnLightBackground && styles.dark}`}
    >
      <ul className={styles.breadcrumb__items}>
        <li className={styles.breadcrumb__item}>
          <Link className={styles.breadcrumb__link} href={`/${locale}/`}>
            Home
          </Link>
        </li>
        {breadcrumb.map((item: IBreadcrumb) => BreadcrumbItem(item))}
        {pathsWithPageTitle.length > 5 && (
          <li className={styles.breadcrumb__item}>...</li>
        )}
        {breadcrumbCurrent?.map((item: IBreadcrumb) => BreadcrumbItem(item))}
      </ul>
    </div>
  );
};

export default Breadcrumb;
