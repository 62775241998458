import styles from './ComponentFeaturedItems.module.scss';

import Image from 'next/image';

import {
  ComponentCtaButton,
  ComponentRichText,
  RelatedInsightWrapper,
  ResponsiveImage,
  getRelatedInsightsWrapperProps,
} from '@/ui';
import { convertMarkdown } from '@/ui/utils';
import { FeaturedItemsComponent, ImageHeadingBodyItem } from '@/types';
import { useEffect, useState } from 'react';

const ComponentFeaturedItems = ({
  informationListingStyle,
  informationListingKeyline,
  informationListingHeading,
  informationListingRichText,
  informationListingItems,
  informationListingCalloutCta,
  relatedInsight,
}: FeaturedItemsComponent) => {
  const layoutStyle = `layout${informationListingStyle.replaceAll(' ', '')}`;
  const keyline = `keyline_${informationListingKeyline?.toLowerCase()}`;

  const [horizontal, setHorizontal] = useState(false);

  useEffect(() => {
    if (keyline === 'keyline_horizontal') {
      setHorizontal(true);
    }
  }, [keyline]);

  const relatedInsightsWrapperProps = getRelatedInsightsWrapperProps({
    component: relatedInsight,
    headingVariant: 'h3',
  });

  return (
    <section
      className={`${styles.featuredItems} ${
        relatedInsight ? styles.hasRelatedInsights : ''
      }`}
    >
      {informationListingHeading && (
        <h2 className={styles.featuredItems__heading}>
          {informationListingHeading}
        </h2>
      )}

      {informationListingRichText && (
        <div className={styles.featuredItems__richtext}>
          <ComponentRichText
            richTextBody={informationListingRichText}
            isStandalone={false}
          />
        </div>
      )}

      {informationListingItems && (
        <div className={styles.featuredItems__content}>
          <div
            className={`${styles.featuredItems__grid} ${styles[layoutStyle]}`}
          >
            {informationListingItems.map(
              (item: ImageHeadingBodyItem, index: number) => {
                const {
                  ihbHeading,
                  ihbHeadline,
                  ihbBody,
                  ihbImage,
                  ihbCta,
                  ihbIcon,
                } = item;

                const heading = ihbHeadline ?? ihbHeading;
                const imageallowed = !!ihbImage && horizontal;

                if (!ihbHeading && !ihbBody) return null;

                return (
                  <div
                    className={`${styles.featuredItems__item} ${styles[keyline]}`}
                    key={`featuredItem_${item.sys?.id}_${index}_key`}
                  >
                    {imageallowed && (
                      <div className={styles.featuredItems__itemImageWrapper}>
                        <ResponsiveImage
                          media={ihbImage}
                          sizes={{ sm: 160 }}
                          className={styles.featuredItems__itemImage}
                        />
                      </div>
                    )}

                    {(ihbIcon || heading) && (
                      <div className={styles.featuredItems__itemHeadingWrapper}>
                        {ihbIcon && !imageallowed && (
                          <div className={`${styles.featuredItems__icon}`}>
                            <Image
                              src={ihbIcon.url}
                              alt={heading}
                              width={ihbIcon.width}
                              height={ihbIcon.height}
                              quality={100}
                            />
                          </div>
                        )}

                        {heading && !ihbCta && (
                          <h3 className={styles.featuredItems__itemHeading}>
                            {heading}
                          </h3>
                        )}

                        {!!ihbCta && (
                          <ComponentCtaButton
                            ctaType='link'
                            ctaStyle='external'
                            ctaText={heading}
                            ctaIcon='external'
                            ctaIconPosition='right'
                            ctaUrl={ihbCta.ctaUrl}
                            ctaPage={ihbCta.ctaPage}
                            ctaMarketoFormId={ihbCta.ctaMarketoFormId}
                            ctaDigitalAsset={ihbCta.ctaDigitalAsset}
                            cssClass={styles.featuredItems__itemCta}
                          />
                        )}
                      </div>
                    )}

                    {ihbBody && (
                      <div className={styles.featuredItems__itemContent}>
                        {convertMarkdown(ihbBody)}
                      </div>
                    )}
                  </div>
                );
              }
            )}
          </div>
        </div>
      )}

      {relatedInsightsWrapperProps && (
        <div className={styles.featuredItems__relatedInsights}>
          <RelatedInsightWrapper {...relatedInsightsWrapperProps} />
        </div>
      )}

      {informationListingCalloutCta && (
        <div className={styles.featuredItems__cta}>
          <ComponentCtaButton
            {...informationListingCalloutCta}
            ctaType='link'
          />
        </div>
      )}
    </section>
  );
};

export default ComponentFeaturedItems;
