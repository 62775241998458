import { ReactNode } from 'react';
import Link from 'next/link';

import { useFullPath } from '../hooks';

interface DataLinkProps {
  url: string;
  tabIndex?: number;
  className?: string;
  children: ReactNode;
  locale?: false | undefined;
  openInNewTab?: boolean;
}

export const DataLink = ({
  url,
  className,
  tabIndex = 0,
  children,
  locale,
  openInNewTab,
}: DataLinkProps) => {
  const fullUrlPath = useFullPath(url) as string;

  return (
    <Link
      className={className}
      href={fullUrlPath}
      tabIndex={tabIndex}
      locale={locale}
      target={openInNewTab ? '_blank' : '_self'}
    >
      {children}
    </Link>
  );
};
