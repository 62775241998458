import styles from './Modal.module.scss';

import { ReactNode, useRef, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';

import { Icon, Portal } from '@/ui';
import { focusableElements } from '@/ui/constants';

interface IModal {
  children: ReactNode;
  isOpen?: boolean;
  triggerId: string;
  handleClose: () => void;
  isLarge?: boolean;
  fullContent?: boolean;
  modalClassname?: string;
  isLightBox?: boolean;
}

function Modal({
  children,
  isOpen = false,
  triggerId,
  handleClose,
  isLarge,
  fullContent,
  modalClassname,
  isLightBox,
}: IModal) {
  const nodeRef = useRef(null);
  const closeButton = useRef<HTMLButtonElement>(null);
  const [modalClosed, setModalClosed] = useState(false);

  const trapFocus = (e: KeyboardEvent) => {
    if (!nodeRef?.current) return;

    const modal = nodeRef.current as HTMLElement;
    const focusable = modal.querySelectorAll(
      focusableElements
    ) as NodeListOf<HTMLElement>;

    if (e.shiftKey) {
      if (document.activeElement === focusable[0]) {
        focusable[focusable.length - 1].focus();
        e.preventDefault();
      }
    } else if (document.activeElement === focusable[focusable.length - 1]) {
      focusable[0].focus();
      e.preventDefault();
    }
  };

  const handleKeydown = (e: KeyboardEvent) => {
    const isTabPressed = e.key === 'Tab';
    const isEscPressed = e.key === 'Escape';

    if (!isTabPressed && !isEscPressed) return;

    if (isTabPressed) trapFocus(e);

    if (isEscPressed) handleModalClose();
  };

  const handleModalClose = () => {
    setModalClosed(true);
    handleClose();
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        closeButton?.current?.focus();
      }, 1);
      document.addEventListener('keydown', handleKeydown);
    }

    if (modalClosed) {
      document.removeEventListener('keydown', handleKeydown);
      document.getElementById(triggerId)?.focus();
    }
  }, [isOpen, modalClosed]);

  return (
    <Portal>
      <CSSTransition
        in={isOpen}
        timeout={0}
        unmountOnExit
        classNames='modal'
        nodeRef={nodeRef}
      >
        <div
          className={`${styles.modal} ${modalClassname} ${
            isLightBox ? styles.lightBox : ''
          }`}
          ref={nodeRef}
        >
          <button
            type='button'
            onClick={handleModalClose}
            className={styles.modal__close}
            ref={closeButton}
          >
            <Icon
              className={styles.modal__closeIcon}
              id='close'
              width={16}
              height={16}
            />

            <span className={styles.modal__closeText}>Close</span>
          </button>

          <div
            className={cn(styles.modal__content, {
              [styles.isLarge]: isLarge,
              [styles.isLightBox]: isLightBox,
              [styles.fullContent]: fullContent,
            })}
          >
            {children}
          </div>
        </div>
      </CSSTransition>
    </Portal>
  );
}
export default Modal;
