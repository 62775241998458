import styles from './ComponentRichTextBody.module.scss';

import { ComponentRichText } from '../ComponentRichText';
import {
  ComponentCtaButton,
  MediaCard,
  RelatedInsightWrapper,
  getRelatedInsightsWrapperProps,
} from '@/ui';
import React from 'react';
import { DataFeaturedLink, PagePreview } from '@/types';

export interface ComponentRichTextBodyProps {
  richTextBodyComponents: any[];
  isStandalone?: boolean;
  relatedInsightHeader?: string;
  relatedInsight?: PagePreview | DataFeaturedLink;
}

const ComponentRichTextBody = ({
  richTextBodyComponents,
  isStandalone = true,
  relatedInsightHeader,
  relatedInsight,
}: ComponentRichTextBodyProps) => {
  const relatedInsightsWrapperProps = getRelatedInsightsWrapperProps({
    component: relatedInsight,
    heading: relatedInsightHeader,
    headingVariant: 'h2',
  });

  return (
    <React.Fragment>
      <div
        className={`${styles.richTextBody} ${
          isStandalone ? styles.standalone : ''
        }`}
      >
        <div className={styles.richTextBody__main}>
          {richTextBodyComponents?.map((component: any, index: number) => {
            return (
              <React.Fragment key={`${component.sys.id}_${index}_key`}>
                {component.__typename === 'DataRichText' && (
                  <div className={styles.richTextBody__textBody}>
                    <ComponentRichText
                      richTextBody={component.bodyContent}
                      isStandalone={false}
                    />
                  </div>
                )}

                {component.__typename === 'DataVideo' && (
                  <div className={styles.richTextBody__mediaCard}>
                    <MediaCard {...component} />
                  </div>
                )}

                {component.__typename === 'ComponentCta' && (
                  <div className={styles.richTextBody__componentCta}>
                    <ComponentCtaButton {...component} />
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>

        {relatedInsightsWrapperProps && (
          <aside className={styles.richTextBody__aside}>
            <RelatedInsightWrapper {...relatedInsightsWrapperProps} />
          </aside>
        )}
      </div>
    </React.Fragment>
  );
};

export default ComponentRichTextBody;
