import styles from './ComponentMultiCardCallout.module.scss';

import { convertMarkdown } from '@/ui/utils';
import { Card } from '@/ui/base';

import { MultiCardCalloutComponent } from '@/types';

const ComponentMultiCardCallout = ({
  calloutHeading,
  ctaText,
  cards,
  insightsMultiCardCallout,
}: MultiCardCalloutComponent) => {
  const cardCount = cards?.length;

  const gridStyle =
    cardCount === 4 || cardCount > 6 ? styles.rowOfFour : styles.rowOfThree;

  const showHeading = calloutHeading && calloutHeading.length > 0;
  const cardHeadingVariant = showHeading ? 'h3' : 'h2';

  return (
    <section className={styles.multiCardCallout}>
      <div
        className={`${styles.multiCardCallout__inner} ${
          insightsMultiCardCallout ? styles.insights : ''
        }`}
      >
        {showHeading && (
          <h2 className={styles.multiCardCallout__heading}>{calloutHeading}</h2>
        )}
        <ul className={`${styles.multiCardCallout__list} ${gridStyle}`}>
          {cards.map((card) => {
            const {
              slug,
              pageHeading,
              previewMedia,
              previewText,
              navigationTag,
              pageTag,
            } = card;

            return (
              <li key={card.slug} className={styles.multiCardCallout__item}>
                <Card
                  cardHeadingVariant={cardHeadingVariant}
                  heading={pageHeading}
                  link={slug}
                  image={previewMedia}
                  navigationTag={navigationTag}
                  tags={pageTag}
                  ctaText={ctaText}
                  useTopic={insightsMultiCardCallout ? true : false}
                >
                  {previewText && convertMarkdown(previewText)}
                </Card>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default ComponentMultiCardCallout;
