import { ComponentCtaButton } from '@/ui/components';

import { FilterTag } from '@/types';

export const FilterTagPill = ({ tagText, onClick, active }: FilterTag) => {
  return (
    <ComponentCtaButton
      buttonType='button'
      ctaType='button'
      ctaText={tagText}
      ctaAria={tagText}
      ctaStyle='outline'
      ctaSize='small'
      cssClass={`filtertag ${active ? 'active' : ''} `}
      onClick={onClick}
    />
  );
};

export default FilterTagPill;
