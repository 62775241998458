import ReactPlayer from 'react-player/lazy';

import styles from './HeroVideoClip.module.scss';

const HeroVideoClip = ({
  heroMedia,
  isPlaying = true,
  ovalBottom = false,
  priority = false,
  loop = true,
  isMuted,
  onEnded = () => {},
}: {
  heroMedia?: any;
  isPlaying?: boolean;
  ovalBottom?: boolean;
  priority?: boolean;
  loop?: boolean;
  isMuted?: boolean;
  onEnded?: () => void;
}) => {
  const muted = isMuted ?? true;

  return (
    <div
      className={`${styles.heroVideoClip__clipped} ${
        ovalBottom && styles.mobileBottomOval
      }`}
    >
      <ReactPlayer
        className={`${styles.homepageHero__backgroundMedia}`}
        url={heroMedia?.url}
        width='100%'
        height='100%'
        playing={isPlaying}
        muted={muted}
        loop={loop}
        playsinline
        controls={false}
        onEnded={onEnded}
      />
    </div>
  );
};

export default HeroVideoClip;
