import { ContentfulClient } from './client';
import { convertCollectionItems } from './builder';
import { baseQuery, newsletterQuery } from './queries/';
import { InsightTypeRef } from '@/types';
import { assetFields, insightTypeReferenceFields, pageTagCollectionFields } from './fields';

export const runGraphQLQuery = async (query: string, locale: string, preview = false) => {
  try {
    const client = new ContentfulClient();
    const response = await client.fetchGraphQL(query, preview);
    const convertedCollectionItems = convertCollectionItems(response?.data);
    const collectionItemsWithLocale = { ...convertedCollectionItems, locale };
    return collectionItemsWithLocale;
  } catch (err) {
    console.log('Error executing GraphQL query');
    console.log(query.replace(/(\r\n|\n|\r)/gm, '').replace(/  +/g, ' '));
    console.log(JSON.stringify(err));
    return {};
  }
};

export const getInsightsPerPageData = async (locale:string, preview = false ) => {

  const query = `{pageInsightsListingCollection{items{insightsPerPage}}}`;
  const data = await runGraphQLQuery(query, locale, preview);
  let value;

  if (data?.pageInsightsListing[0]) {
    value = data.pageInsightsListing[0].insightsPerPage ?? 8;
  }

  return value;
};

export const getInsightsData = async (locale: string, preview = false) => {

  let previewTrue = preview ? 'preview: true' : '';

  const query = `{
    pageStandardPageCollection(
      ${previewTrue}
      locale: "${locale}" 
    ){
      items{
        insightTypeReference(
          locale: "${locale}" 
        ){
          pluralName
        }
      }
    }
  }`;
  const data = await runGraphQLQuery(query, locale, false);
  let insightReference: string[] = [];
  if (data?.pageStandardPage) {
    const validItems = data?.pageStandardPage.filter((item: { insightTypeReference: null; }) => item.insightTypeReference !== null);
    validItems.forEach((item: InsightTypeRef) => {
      if (item.insightTypeReference?.pluralName) {
        insightReference.push(item.insightTypeReference.pluralName);
      }
    });
  }
  return insightReference;
};

export const getLatestInsightsCards = async (locale:string, preview = false) => {  
  let previewTrue = preview ? 'preview: true' : '';
  const query = `{
    pageStandardPageCollection(
      limit: 5
      locale: "${locale}"
      ${previewTrue}
      order: publishDate_DESC
      where: {
        slug_exists: true
        publishDate_exists: true
        insightTypeReference_exists: true
      }
    ){
      items {
        sys { id }
        previewMedia(locale: "en") {
          ${assetFields}
        }
        ${insightTypeReferenceFields}
        publishDate
        pageHeading
        slug
        ${pageTagCollectionFields}
      }
    }
  }`;

  const insightCards = await runGraphQLQuery(query, locale, preview);

  const cards = insightCards?.pageStandardPage || [];
  let largeCard = {};
  let smallCards = [];  
  if (cards) {
    largeCard = cards[0];
    smallCards = cards.slice(1);    
  }
  const insightCardsData = { largeCard, smallCards };
  return insightCardsData;
};

export const getBaseStaticProps = async (locale: string, preview = false) =>
  runGraphQLQuery(baseQuery({ locale, preview }), locale, preview);

export const getNewsletterProps = async (entryId: string, locale: string, preview = false) =>
  runGraphQLQuery(newsletterQuery({ entryId, locale, preview }), locale, preview);